import * as React from "react"
import {LabelContext} from "../../core/label.context"
import {CartContext} from "../../core/cart.context"
import {GeneralContext} from "../../core/general.context"
import {getCurrentPrice, getEnergyClassLabel} from "../bundle/utils"
import {
    APPLY_ECO_PART_TO_PRICE,
    BRX_PRODUCTS_URL, calls,
    PROJECT_COUNTRY,
    PROJECT_LANGUAGE,
    SHOW_ENERGY_LABEL
} from "../../core/APIUtils"
import {openCartLayer, parsePrice} from "../../common/constants"
import { StyledFrequentlyBought, StyledFrequentlyBoughtList } from "./frequentlyBought.style";
import {RESTContext} from "../../core/rest.context";
import { createPortal } from "react-dom"
import { isMobile } from "react-device-detect"

export const SliderFromSkuList = () => {
    const container = document.getElementById("reactPlaceholder_productsSliderFromSkuList")
    const skuList = container?.getAttribute("data-skus") || ""
    const title = container?.getAttribute("data-title") || ""
    // added for the need to have 3 elements in the recipes (cooking), if it is not specified 6
    const numberOfProductsToShow= Number(container?.getAttribute("data-numberOfProductsToShow")) || 6
    const { ajax$ } = React.useContext(RESTContext);
    const [products, setProducts] = React.useState<any>([]);

    React.useEffect(() => {
        const skusString = skuList
            .split(",")
            .map((sku) => `"${sku.trim()}"`)
            .join(", ");
        const where = `masterVariant(sku in (${skusString}))`;
        ajax$(calls.Project.queryProductProjection(where))
            .subscribe((res: any) => {
                setProducts(res?.results)
            })
    },[])

    if (!container)
        return null;
    else {
        return <>
            {products && products.length > 0 && (
                createPortal(
                    <FrequentlyBoughtComponent relatedItems={products} relatedAccessories={[]} title={title} numberOfProductsToShow={numberOfProductsToShow} />,
                    container
                )
            )}
        </>
    }
};

export const FrequentlyBoughtComponent = (props: any) => {
    const { relatedItems, relatedAccessories, title, numberOfProductsToShow } = props;
    const { t } = React.useContext(LabelContext);
    const [productsToShow, setProductsToShow] = React.useState<any>([])
    const [itemCount, setItemCount] = React.useState(numberOfProductsToShow);

    const showMoreItems = () => {
        setItemCount(itemCount + numberOfProductsToShow);
    };

    const showLessItems = () => {
        setItemCount(numberOfProductsToShow);
    };

    React.useEffect(() => {

        //get only product with inventory > 0
        const relProduct = relatedItems.filter((item: any) => {
            if (item.obj) {
                return item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity > 0
            } else {
                return item.masterVariant?.availability?.availableQuantity > 0
            }
        })

        //get only accessories with inventory > 0
        const relAccessories = relatedAccessories.filter((item: any) => {
            if (item.obj) {
                return item.obj && item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity > 0
            } else {
                return item.masterVariant?.availability?.availableQuantity > 0
            }
        })

        //merge product and accessories
        const mergedArray = [...relProduct, ...relAccessories]

        //remove duplicates
        const uniqueProducts = mergedArray.reduce((unique: any[], product: any) => {
            if (product.obj && product.obj.key) {
                const existingProduct = unique.find(p => p.obj && p.obj.key === product.obj.key);
                if (!existingProduct) {
                    unique.push(product);
                }
            } else {
                if(product.key){
                    const existingProduct = unique.find(p => p.key === product.key);
                    if (!existingProduct) {
                        unique.push(product);
                    }
                }
            }
            return unique;
        }, []);


        setProductsToShow(uniqueProducts)
    },[])


    return (<>
        {productsToShow.length > 0 &&
        <StyledFrequentlyBoughtList>
            <section className="ecommerce_relatedItems white-section">
                {/* i need h1 tag in cooking, cooking need 3 elements */}
                {numberOfProductsToShow === 3 ? (
                    <h1 className="title">{title ? title : t("ecommerce.common.related.title")}</h1>
                ): (
                    <h2 className="title text-black">{title ? title : t("ecommerce.common.related.title")}</h2>
                )}
                <div className="products-list-small">
                        {
                            productsToShow.slice(0, itemCount).map((item: any, i: any) => {
                                return (<FrequentlyBought key={i} item={item} i={i} />)
                            })
                        }
                        {itemCount < productsToShow.length && (
                            <button onClick={showMoreItems } className="btn-viewMore">
                                <span className="icon-more">+</span><span> {t("ecommerce.common.viewMore")}</span>
                            </button>
                        ) }
                        {itemCount >= productsToShow.length && itemCount > numberOfProductsToShow && (
                            <button onClick={showLessItems} className="btn-viewMore">
                                <span className="icon-more">-</span><span>{t("ecommerce.common.viewLess")}</span>
                            </button>
                        )}
                </div>
            </section>
            </StyledFrequentlyBoughtList>
        }
    </>)
}

export const FrequentlyBought = (props: any) => {
    const { item, i } = props;
    const { t } = React.useContext(LabelContext);
    const { addLineItem } = React.useContext(CartContext);
    const { wishlist, userData } = React.useContext(GeneralContext)
    const [ecoPartecipationPrice, setEcoPartecipationPrice] = React.useState(0)
    const [ecoPartLabel, setEcoPartLabel] = React.useState<string>('')

    let currentItem: any
    let name: string
    let available: any
    let isSellable: any

    if (item.obj) {
        currentItem = item.obj?.masterData?.current?.masterVariant
        name = item.obj?.masterData?.current?.name;
    } else {
        currentItem = item.masterVariant
        name = item.name
    }

    React.useEffect(() => {
        const ecoPrice = currentItem?.attributes?.find((attribute: any) => attribute.name === 'ecoPartecipationPrice')?.value || 0;
        setEcoPartecipationPrice(ecoPrice);

        if (ecoPrice > 0) {
            setEcoPartLabel(t("ecommerce.common.product.ecoPartecipation").replace("[price]", ecoPrice.toString()));
        }
    }, [currentItem]);

    available = currentItem.availability?.availableQuantity
    isSellable = currentItem?.availability

    const energyLabel = getEnergyClassLabel(currentItem, t)
    const productSheetUrl = currentItem?.attributes.find((attr: any) => attr.name === 'product-sheet-url' && attr.value)

    const { sku, images, prices } = currentItem;
    const { discounted } = getCurrentPrice(prices, userData);

    let inWishlist: any = false
    if (wishlist && wishlist.lineItems.length > 0) {
        inWishlist = wishlist.lineItems.find((item) => (item.variant && item.variant.sku === sku));
    }

    return (
        <StyledFrequentlyBought key={i} className="small-listItem row mx-0 mr-3">
            <div className="product-content col-12 col-md-12 col-lg-8">
                <a href={BRX_PRODUCTS_URL + sku}>
                    <div className="product-preview__gallery">
                        <img src={images[0].url} alt="" />
                    </div>
                    <div className="product-preview__header">
                        <div className="name" >{name[PROJECT_LANGUAGE]}</div>
                        
                        <div className="promotion-preview">
                            <div className="promotion-preview__header">
                            <div className="name-sku">{sku}</div>
                                <div className="prices">
                                    {getCurrentPrice(prices, userData)?.value.centAmount > 0 && (
                                        discounted ? (
                                            <>
                                                <div className="price price--strikethrough">{parsePrice(getCurrentPrice(prices, userData)?.value, undefined, undefined, ecoPartecipationPrice)}</div>
                                                <div className="price price--discounted">{parsePrice(discounted.value, undefined, undefined, ecoPartecipationPrice)}</div>
                                            </>
                                        ) : (
                                            <div className="price">{parsePrice(getCurrentPrice(prices, userData)?.value, undefined, undefined, ecoPartecipationPrice)}</div>
                                        )

                                    )}
                                </div>
                                {APPLY_ECO_PART_TO_PRICE && ecoPartecipationPrice > 0 && ecoPartLabel !== "" &&
                                    <div className="ecoPartecipation-frequentlyBought" dangerouslySetInnerHTML={{ __html: ecoPartLabel }}></div>
                                }
                            </div>
                        </div>
                        <div className="d-inline-flex mx-0 energy_label_text">
                            {SHOW_ENERGY_LABEL && (energyLabel || (productSheetUrl && productSheetUrl !== "")) &&
                                <>
                                    {energyLabel &&
                                        <a target="_blank" rel="noreferrer" href={`https://pi-exchange.smeg.it/energy/${sku}/${PROJECT_LANGUAGE}-${PROJECT_COUNTRY}`}>
                                            <img src={energyLabel.src} alt={energyLabel.alt} />
                                        </a>
                                    }
                                    {productSheetUrl && productSheetUrl !== "" &&
                                        <a href={productSheetUrl.value?.replaceAll(" en-GB", " en-IE")} target="_blank" rel="noreferrer" className={`font-weight-light`}>{t("product.productSheetLabel")}</a>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </a>
                {window.innerWidth < 992 && (
                    <>
                        <div className={`product-link__buy d-flex`}>
                            {/* add img here to avoid using margin with fixed value. can cause error when img is not found */}
                            <img className="invisible" src={images[0].url} alt="" style={{maxWidth: "100px", marginRight: "15px"}}/>

                            {available &&
                                <button data-id={sku} onClick={() => { addLineItem(sku); openCartLayer(); }} className="btn_addToCart button-primary" title={t("ecommerce.common.product.addToCart")}>
                                    <span className="btn-text">{t("ecommerce.common.product.addToCartShort")}</span>
                                </button>
                            }
                            {!available &&
                                <>
                                    {isSellable && <div style={{"lineHeight": "18px"}} className="text-center focus" dangerouslySetInnerHTML={{__html: t("ecommerce.common.product.isOutOfStock")}}/>}
                                    <div><br /></div>
                                </>
                            }
                        </div>
                    </>
                    
                )}
            </div>

            {window.innerWidth > 992 && (
                <div className="product-links col-2 col-md-3 col-lg-4">
                    <div className="product-link__buy d-flex justify-content-end">
                        {available &&
                            <button data-id={sku} onClick={() => { addLineItem(sku); openCartLayer(); }} className="btn_addToCart button-primary" title={t("ecommerce.common.product.addToCart")}>
                                <span className="btn-text">{t("ecommerce.common.product.addToCartShort")}</span>
                            </button>
                        }
                        {!available &&
                            <>
                                {isSellable && <div style={{"lineHeight": "18px"}} className="text-center focus" dangerouslySetInnerHTML={{__html: t("ecommerce.common.product.isOutOfStock")}}/>}
                                <div><br /></div>
                            </>
                        }
                    </div>
                    {/* <div className="product-link__wishlist">
                        <button data-id={sku} onClick={() => addRemoveWishlistLineItem(sku)} className={`${inWishlist && "inWishlist"} btn_addToWishlist`} title="Wishlist">
                            <span className="icon"></span>
                            <span className="testo">{t("ecommerce.common.product.addToWishlist")}</span>
                            <span className="testo2">{t("ecommerce.common.product.addedToWishlist")}</span>
                        </button>
                    </div> */}
                </div>
            )}
            
        </StyledFrequentlyBought>
    )
};