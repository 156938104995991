import * as React from "react";
import ReactDOM from "react-dom";
import { GeneralContext } from "../core/general.context";
import { StyledPages } from "./commonPages.style";
import { StyledCartPage } from "./cartPage.style";
import { Cart } from "../components/cart/cart.component";
import { LabelContext } from "../core/label.context";
import {BRX_BASE_URL, calls, PROJECT_SETTINGS} from "../core/APIUtils";
import { Box } from "../components/box/box.component";
import { CartContext } from "../core/cart.context";
import {RESTContext} from "../core/rest.context"
import {
    isBundleAvailable,
    isBundleInCart,
    isBundleCompatibleWithCart,
    getBundlePrice,
    removeBundleCancelledFromProductProjection
} from "../components/bundle/utils"
import {FrequentlyBoughtComponent} from "../components/sliders/frequentlyBought.component"
import {useState} from "react";

export const CartPage = (props: any) => {
    const { t } = React.useContext(LabelContext);
    const { cart, userData } = React.useContext(GeneralContext);
    const { isEmptyCart,
        numberOfProducts } = React.useContext(CartContext);
    const {ajax$} = React.useContext(RESTContext);
    const container = document.getElementById("cart");
    const [relatedBundles, setRelatedBundles] = React.useState<any>([]);

    React.useEffect(() => {
        
        if(container && cart.lineItems.length > 0) {
            ajax$(calls.Project.getProductProjection('productType.id:"' + PROJECT_SETTINGS['bundleId'] + '"&expand=masterVariant.attributes[*].value[*]'))
                .subscribe(
                    res => {
                        //rimuovo i bundle che contengono prodotti non piu presenti su elastic e quindi cancellati
                        const filteredBundles = removeBundleCancelledFromProductProjection(res.results)

                        let bundleToShow: any [] = []
                        filteredBundles.forEach((_bundle: any) => {

                            // Mostriamo solo i bundle che non sono già nel carrelo
                            let isInCart = isBundleInCart(_bundle, cart)

                            let isCompatibleWithCart = isBundleCompatibleWithCart(_bundle, cart)

                            let isAvailable = isBundleAvailable(_bundle).bundleAvailable
                            

                            if(!isInCart && isAvailable && isCompatibleWithCart){
                                let prices = getBundlePrice(_bundle, userData, false)
                                _bundle.available = isAvailable
                                _bundle.price = prices.productsPriceFormatted
                                _bundle.discountPercentValue = prices.discountPercentValue
                                bundleToShow.push(_bundle);
                            }
                        })
                        setRelatedBundles(bundleToShow)
                    }
                )
        }
    }, [cart])

    const relatedItems = cart.lineItems
        .map(lineItem => lineItem.variant.attributes.find(attr => attr.name === "related-products")?.value)
        .flat(1)
        .filter(item => item);

    const relatedAccessories = cart.lineItems
        .map(lineItem => lineItem.variant.attributes.find(attr => attr.name === "accessories")?.value)
        .flat(1)
        .filter(item => item);

    if (!container)
        return null;
    else
        return ReactDOM.createPortal(
            <StyledPages>
                <StyledCartPage>
                    <section className="component cmp_cart tpl_default default-section white-section pv-50">
                        <div className="container">
                            <div className="row row-cartTitle no-gutters">
                                <h1 className="col no-gutters">{t("ecommerce.cart.page.title")}</h1>
                                {!isEmptyCart &&
                                    <div
                                        className="product-count col no-gutters">{numberOfProducts} {(numberOfProducts === 1) ? t("ecommerce.checkout.productInCart") : t("ecommerce.checkout.productsInCart")}</div>
                                }
                            </div>
                        </div>
                        <div className="container">
                            <div className="row no-gutters">
                                {isEmptyCart ?
                                    <>
                                        <Box className="secondary">
                                            <div className="emptyCart">
                                                <p>{t("ecommerce.cart.empty")}</p>
                                                <a href={BRX_BASE_URL}>  &lt; {t("ecommerce.checkout.btn.backtoshop")} </a>
                                            </div>
                                        </Box>
                                    </>
                                    : ////// else
                                    <>
                                        <Cart relatedBundles={relatedBundles}/>
                                    </>
                                }


                                {/*{!isEmptyCart && relatedItems.length >= 1 &&
                                <SliderItems items={relatedItems} type={"related"}/>
                            }

                            {!isEmptyCart && relatedAccessories.length >= 1 &&
                                <SliderItems items={relatedAccessories} title={t('ecommerce.common.relatedAccessories.title')} type={"related"}/>
                            }*/}

                                {!isEmptyCart && (relatedAccessories.length >= 1 || relatedItems.length >= 1) &&
                                    <FrequentlyBoughtComponent relatedItems={relatedItems}
                                                               relatedAccessories={relatedAccessories}
                                                               title={t('ecommerce.common.frequentlyBought.title')}
                                                               numberOfProductsToShow={6}/>
                                }

                                {/*!isEmptyCart && relatedBundles.length >= 1 &&
                                <SliderItems items={relatedBundles} title={t('ecommerce.common.relatedBundles.title')} type={"bundle"}/>
                            */}
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div id="reactPlaceholder_productsSliderFromSkuList" data-skus="xxx,yyy,zzz"/>
                            </div>
                        </div>
                    </section>
                </StyledCartPage>
            </StyledPages>
            , container
        )
};