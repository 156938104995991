import React from "react"
import {CartContext} from "../../core/cart.context"

import {CheckoutContext} from "../../core/checkout.context"
import {KlarnaCheckoutStep1, KlarnaCheckoutStep2} from "./klarnacheckout.steps.component"
import { StyledCheckout } from "../checkout/checkout.style"
import {CartRecap} from "../cart/cart.recap.component";
import {IS_GIFT_CARD_ACTIVE} from "../../core/APIUtils";

export const KlarnaCheckout = (props: any) => {
    const { redirectResult } = props
    const {checkoutStep} = React.useContext(CheckoutContext)
    const { additionalsDetailsPayment } = React.useContext(CartContext)

    let adyenComponent: any
    React.useEffect(() => {
        if (redirectResult) {
            let details = {
                details: {
                    redirectResult: redirectResult,
                },
            }
            additionalsDetailsPayment(details, adyenComponent)
        }
    }, [redirectResult])

    return (
        <>
            <StyledCheckout>
                <div className="row no-gutters">
                    <div className="checkout-column-main col-lg-8">
                        <div className="wrapper">
                            {checkoutStep === 1 &&
                                <KlarnaCheckoutStep1/>
                            }
                            {checkoutStep === 2 &&
                                <KlarnaCheckoutStep2/>
                            }
                        </div>
                    </div>
                    {IS_GIFT_CARD_ACTIVE &&
                        <aside className="checkout-column-aside col-lg-4">
                            <CartRecap/>
                        </aside>
                    }
                </div>
            </StyledCheckout>
        </>
    )
}
