import { ICart, ILineItem, ITotalPrice } from "./cart.models"
import {PROJECT_CURRENCY, PROJECT_COUNTRY, APPLY_ECO_PART_TO_PRICE} from "../core/APIUtils"
import {browserName, browserVersion, deviceType, isMobile, mobileModel, osName, osVersion} from "react-device-detect"

export const regexPwdValidation = /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.{8,})/

export const getBrowserInfo = () => {
    const userAgentInfo = {
        browser_name: browserName,
        browser_version: browserVersion,
        device_type: deviceType,
        is_mobile: isMobile,
        mobile_vendor: mobileModel,
        os_name: osName,
        os_version: osVersion
    }
    return userAgentInfo
}

export const getCartInfo = (cart: ICart) => {
  let cart_json: any = {}

  if (cart.lineItems.length > 0){
    let _lineItems: { sku: string; qta: number }[] = []
    cart.lineItems.map((line: ILineItem) =>{
      _lineItems.push({ sku: line.productKey, qta: line.quantity})
    })

    cart_json["lineItems"] = _lineItems

    if(cart.shippingAddress){
      cart_json["shippingAddress"] = cart.shippingAddress
    }

    if(cart.billingAddress){
      cart_json["billingAddress"] = cart.billingAddress
    }
  }

  return cart_json
}

export const openCartLayer = () => {
  let btnCartLayer: any = document.getElementsByClassName("ec_btn-cartLayer")[0]
  let CartLayerActive = btnCartLayer.parentNode.classList.contains('open')

  //if cartlayer is active don't close it
  !CartLayerActive && setTimeout(() => {
    btnCartLayer.click()
  }, 300)
}

export const automaticToggleCartLayer = () => {
    let btnCartLayer: any = document.getElementsByClassName("ec_btn-cartLayer")[0];
    setTimeout(() => { btnCartLayer.click(); setTimeout(() => { btnCartLayer.click(); }, 3000) }, 300);
}

export const automaticCloseAccountLayer = () => {
  let element = document.querySelectorAll(".reactPlaceholder_accountLayer_btn.open .ec_btn-accountLayer")[0]
  let btnAccountLayer: HTMLElement = element as HTMLElement
  btnAccountLayer.click()
}

/*
 *
 *	paramenters:
 *	only price: 101,99 €
 *	with raw=true: 101.99
 *
 */
export const parsePrice = (price: ITotalPrice, isRaw: boolean = false, isRounded: boolean = false, ecoPartecipation: number = 0) => {
  if (price === undefined) return ""
  let priceCode = price.currencyCode
  const PRICE_TYPE_CENT_PRECISION = "centPrecision";

  if (isRaw && price.type === PRICE_TYPE_CENT_PRECISION) {
    return (price.centAmount / 100).toFixed(price.fractionDigits);
  }

  let formattedPrice = (price.centAmount / 100);
  if (isRounded) {
    formattedPrice = Math.round(formattedPrice);
  }

  if(APPLY_ECO_PART_TO_PRICE && ecoPartecipation > 0){
    formattedPrice = formattedPrice - ecoPartecipation
  }

  let stringPrice = new Intl.NumberFormat(PROJECT_COUNTRY, {
    style: "currency",
    currency: priceCode,
    minimumFractionDigits: isRounded ? 0 : price.fractionDigits,
    maximumFractionDigits: isRounded ? 0 : price.fractionDigits
  }).format(formattedPrice);

  //Eccezione della funzione Intl.NumberFormat perchè non riconosce SEK e DDK e non ritorna il simbolo Kr
  if (priceCode === 'SEK' || priceCode === 'DKK') {
    return stringPrice.replace(priceCode, 'kr');
  }

  return stringPrice;
}

export const createPrice = (centAmount: number, currencyCode: string) => {
  let price = {
    type: "centPrecision",
    fractionDigits: 2,
    currencyCode: currencyCode,
    centAmount: centAmount,
  }
  return price
}

export const parseDate = (rawDate: string) => {
  const dateObj = new Date(rawDate);

  function padNumber(n: number): string {
    return n < 10 ? '0' + n : '' + n;
  }

  return `${padNumber(dateObj.getDate())}/${padNumber(dateObj.getMonth() + 1)}/${dateObj.getFullYear()}`;
}

export const getPrices = (cart: ICart, isService: boolean) => {
  if (!cart) {
    return {
      totalPrice: createPrice(0, PROJECT_CURRENCY),
      totalPriceDiscounted: createPrice(0, PROJECT_CURRENCY),
      savings: createPrice(0, PROJECT_CURRENCY),
    }
  }
  const { lineItems } = cart
  const condition = (item: ILineItem) =>
    isService ? item.productType.obj?.key === "services" : item.productType.obj?.key !== "services"
  const totalPriceCentAmount = lineItems
    .filter((item) => condition(item))
    .reduce((sum, item) => sum + item.price.value.centAmount * item.quantity, 0)
  const totalPrice = createPrice(totalPriceCentAmount, cart.totalPrice.currencyCode)
  const totalPriceCentAmountDiscounted = cart?.lineItems
    .filter((item) => condition(item))
    .reduce((sum, item) => sum + item.totalPrice.centAmount, 0)
  const totalPriceDiscounted = createPrice(totalPriceCentAmountDiscounted, cart?.totalPrice.currencyCode)
  const savings = createPrice(totalPriceCentAmount - totalPriceCentAmountDiscounted, cart?.totalPrice.currencyCode)
  return {
    totalPrice,
    totalPriceDiscounted,
    savings,
  }
}

export const redirectTo = (path: string) => {
	if (!path) {
		return
	}
	window.location.href = `${path}`;
};

export const inputMaxLength = 30;

declare global {
  let dataLayer: object[];
}

export const getAnalyticsProducts = (lineItems:ILineItem[]) => {
	let products = [];
	products = lineItems?.map((item:any) => {
		return  {
			name: `${Object.values(item?.name)[0]}`,
			id: `${item?.variant?.sku}`,
			price: `${parsePrice(item?.price?.value, true)}`,
			brand: `${item?.variant?.attributes?.find((attr: any) => attr?.name === "NEW_AESTHETICS")?.value?.label}`,
			category: `${item?.variant?.attributes?.find((attr: any) => attr?.name === "family")?.value?.key}`,
			variant: `${item?.variant?.sku}`,
			quantity: `${item?.quantity}`
		}
	});
	return products;

};

export const nordics = [
  "smeg-sweden",
  "smeg-sweden-staging",
  "smeg-sweden-staging2",
  "smeg-norway",
  "smeg-norway-staging",
  "smeg-finland",
  "smeg-finland-staging",
  "smeg-denmark",
  "smeg-denmark-staging",
]
