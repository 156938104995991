import {createPrice, getBrowserInfo, parsePrice} from "../../common/constants";
import {calls, PROJECT_CURRENCY, PROJECT_LANGUAGE, PROJECT_SETTINGS} from "../../core/APIUtils"
import {IUserData} from "../../common/user.models";
import {ICart, ILineItem} from "../../common/cart.models";
import {AWSlogger} from "../../core/rest.context";


export const isBundleAvailable = (bundleProjection: any) => {
    let skuList = getAttributeFromProductProjection(bundleProjection, "bundle")
    let bundleAvailable = true
    let minQta: number | undefined = undefined

    skuList.forEach((item: any) => {
        const availableQuantity = item.obj?.masterData?.current?.masterVariant?.availability?.availableQuantity

        // Controlla la disponibilità del prodotto e aggiorna bundleAvailable se necessario
        if (!item.obj || !item.obj?.masterData.current.masterVariant.availability || (availableQuantity < 1 && PROJECT_SETTINGS.inventoryMode !== "TrackOnly")) {
            bundleAvailable = false
        }

        // Calcola il minQta solo se availableQuantity è definito e valido
        if (availableQuantity !== undefined) {
            if (minQta === undefined) {
                minQta = availableQuantity
            } else if (availableQuantity < minQta) {
                minQta = availableQuantity
            }
        }
    })

    return { bundleAvailable, minQta }
}


export const customDiscountLabel = (attributes: any) => {
    if(!attributes)return null
    let customDiscount = attributes.find((attr: any) => attr.name === 'custom-discount-label' && attr.value && attr.value[PROJECT_LANGUAGE])
    let customDiscountLabel = null
    if(customDiscount){
        customDiscountLabel = customDiscount.value[PROJECT_LANGUAGE]
    }
    return customDiscountLabel
}

export const isBundleInCart = (bundleProjection:any, cart:any) => {
    let found: boolean = false

    let skuInsideCart: string[] = []
	cart.lineItems.map((lineitem: any) => {
	    skuInsideCart.push(lineitem.variant.sku)
	})

    let skuInsideBundle = getAttributeFromProductProjection(bundleProjection, "bundle")

    let numberOfProductOfBundleInCart = 0
    skuInsideBundle.forEach((_el: any) => {
        if (skuInsideCart.indexOf(_el.obj.key) !== -1 && !found) {
            numberOfProductOfBundleInCart++
        }
    })    
    return (numberOfProductOfBundleInCart === skuInsideBundle.length)
}

export const isBundleCompatibleWithCart = (bundleProjection:any, cart:any) => {
    let found: boolean = false

    let skuInsideCart: string[] = []
	cart.lineItems.map((lineitem: any) => {
	    skuInsideCart.push(lineitem.variant.sku)
	})

    let skuInsideBundle = getAttributeFromProductProjection(bundleProjection, "bundle")

    let numberOfProductOfBundleInCart = 0
    skuInsideBundle.forEach((_el: any) => {
        if (skuInsideCart.indexOf(_el.obj.key) !== -1 && !found) {
            numberOfProductOfBundleInCart++
        }
    })    
    return (numberOfProductOfBundleInCart>0)
}

//rimuovo i bundle che hanno dei prodotti che sono stati cancellati perche non presenti piu su elastic (non esiste il campo "obj") oppure sono non pubblicati
export const removeBundleCancelledFromProductProjection = (bundleProjection: any) => {
    return bundleProjection.filter((product: any) => {
        const bundleAttributes = product.masterVariant.attributes.find((attr: any) => attr.name === "bundle")

        if (!bundleAttributes) {
            return false
        }

        return bundleAttributes.value.every((attrValue: any) => attrValue.obj && attrValue.obj.masterData.published)
    })
}

export const getEcoPartTotalFromCart = (cart: ICart) : number => {
    return cart?.lineItems
        ?.flatMap((lineItem: any) =>
            lineItem.variant?.attributes
                ?.filter((attribute: any) => attribute.name === "ecoPartecipationPrice")
                .map((attribute: any) => {
                    const value = attribute.value;
                    const quantity = lineItem.quantity || 1
                    const totalValue = isNaN(value) ? 0 : value * quantity;
                    return totalValue;
                })
        )
        .reduce((sum: number, value: number) => sum + value, 0) || 0;
}

export const getAttributeFromProductProjection = (productProjection:any, attributeName:string) =>{
	let attributes = productProjection.masterVariant?.attributes.filter( 
	    (_a:any) => { return _a.name === attributeName }
	).map( (__a:any) => {return __a.value}  )

	let result = null;
	if(attributes.length>0){
		result = attributes[0]
	}
	return result

}

export const getBundlePrice = (bundleProjection:any, userData: IUserData, getDiscountedPriceFromCart: boolean, cart?: ICart) => {
    let skuList = getAttributeFromProductProjection(bundleProjection, "bundle")
    let res = isBundleAvailable(bundleProjection)
    let minQta = res.minQta
    let isAvailable = res.bundleAvailable

    let bundlePrice
    if (getDiscountedPriceFromCart && cart){
        // Prezzo ottenuto dal carrello (prezzo scontato)
        bundlePrice = getDiscountedBundlePriceFromCart(bundleProjection.key, cart)
    } else {
        // Prezzo impostato sul bundle (prezzo scontato)
        bundlePrice = getCurrentPrice(bundleProjection.masterVariant.prices, userData)?.value.centAmount
    }


    // Prezzo intero dei prodotti del bundle
    let productsPrice: number = 0
    let ecoPartecipationPrice = 0
    skuList.forEach((item: any) => {
        let ecoPartProduct = item.obj?.masterData.current.masterVariant.attributes.find((attribute: any) => attribute.name === 'ecoPartecipationPrice')?.value || 0
        ecoPartecipationPrice += ecoPartProduct
        let _price = getCurrentPrice(item.obj?.masterData.current.masterVariant.prices, userData)?.value.centAmount
        productsPrice = productsPrice + _price
    });

    let bundlePriceFormatted = bundlePrice? parsePrice(createPrice(bundlePrice, PROJECT_CURRENCY), undefined, undefined, ecoPartecipationPrice) : null

    let productsPriceFormatted = parsePrice(createPrice(productsPrice, PROJECT_CURRENCY), undefined, undefined, ecoPartecipationPrice)

    let discountPercentValue;
    if(productsPrice>0 && bundlePrice){
        discountPercentValue = Math.round(100 * (productsPrice - bundlePrice) / productsPrice)
    } else {
        discountPercentValue = 0
    }

    let savingPrice = 0
    let savingPriceFormatted = ""
    if(bundlePrice && bundlePrice > 0){
        savingPrice = productsPrice-bundlePrice
        savingPriceFormatted = parsePrice(createPrice(productsPrice-bundlePrice,PROJECT_CURRENCY))
    }

    let result: any = {
        bundlePrice: bundlePrice, 
        productsPrice: productsPrice,
        savingPrice: savingPrice,
        bundlePriceFormatted: bundlePriceFormatted, 
        productsPriceFormatted: productsPriceFormatted,
        savingPriceFormatted: savingPriceFormatted,
        discountPercentValue: discountPercentValue,
        isAvailable: isAvailable,
        minQta: minQta,
        ecoPartecipationPrice: ecoPartecipationPrice
    }
    
    return result
}

export const getContentVisibility = (visibilityClass: string, type: string) => {
    let result = ""
    if(visibilityClass && visibilityClass !== ""){
        if(visibilityClass === "1"){
            result = `content-for-logged-${type}`
        } else if(visibilityClass == "2"){
            result = `content-for-guest-${type}`
        } else {
            result = `content-for-all-${type}`
        }
        return result
    }
}

export const getDiscountedBundlePriceFromCart = (bundleKey: string, cart: ICart): number => {
    let totalDiscountedPrice = 0;

    const bundleItems = cart.lineItems.filter(item =>
        item.custom &&
        item.custom.fields &&
        item.custom.fields["lineitem-bundle"] === bundleKey
    );

    bundleItems.forEach((item: ILineItem) => {
        if(item.discountedPrice && item.discountedPrice.value){
            totalDiscountedPrice += item.discountedPrice.value.centAmount
        }
        /*// Controllo dell'array discountedPricePerQuantity
        if (item.discountedPricePerQuantity) {
            item.discountedPricePerQuantity.forEach((discountedQuantity) => {
                if (discountedQuantity.discountedPrice && discountedQuantity.discountedPrice.value) {
                    totalDiscountedPrice += discountedQuantity.discountedPrice.value.centAmount;
                }
            });
        }*/
    });

    return totalDiscountedPrice
};


export const getCurrentPrice = (prices: any [], userData: IUserData) => {
    let price_output = undefined

    // Funzione per verificare se il prezzo è valido
    const isPriceValid = (price: any) => {
        let now = new Date()
        if (price.validFrom && price.validUntil) {
            let from = new Date(price.validFrom)
            let until = new Date(price.validUntil)
            return from <= now && now <= until
        }
        return false
    }

    // Cerca il prezzo originale
    let original_price = prices.find((price) => price.key === 'original');

    if (!original_price) {
        original_price = prices.find((price) => price.key === '' || !price.key);
    }

    if (original_price) {
        price_output = original_price
    }

    // Cerca il primo prezzo futuro valido
    let future_price = prices.find((price: any) =>
        (price.key === 'future-price-1' || price.key === 'future-price-2') && isPriceValid(price)
    )
    if (future_price) {
        price_output = future_price
    }

    // Cerca il primo prezzo VIP valido
    let vip_price = prices.find((price: any) => price.key === 'vip-price' && isPriceValid(price));
    if (vip_price && userData?.customerGroup?.obj?.key === 'vip-users') {
        price_output = vip_price;
    }

    return price_output
}

export const getEnergyClassLabel = (masterVariant: any, t: (labelValue: string) => string) => {
    const energyLabelValue: string = masterVariant?.attributes.find((attr: any) => attr.name === "ENERGY_CLASS_ICON")?.value?.key

    if(energyLabelValue){
        const label = t(energyLabelValue.replaceAll("Plus", "+"))
        return {
            src: `https://smegpix.4flow.cloud/smegpixpath/Symbols/${energyLabelValue.replaceAll("Plus", "+")}.jpg`,
            alt: label
        }
    }
    return undefined
}

