import React from "react";
import {tap} from "rxjs/operators";
import {ICart, ILineItem} from "../common/cart.models";
import {EAddressType, ICartContext} from "../common/common.models";
import {createPrice, getBrowserInfo, getPrices, openCartLayer, parsePrice} from "../common/constants";
import {ESetAddress} from "../components/checkout/checkout.models";
import {LabelContext} from "./label.context";
import {AlertContext} from "./alert.context";
import {
    calls,
    PROJECT_KEY,
    PROJECT_LANGUAGE,
    BRX_BASE_URL,
    PROJECT_CURRENCY,
    PROJECT_COUNTRY,
    ADYEN_MERCHANTACCOUNT,
    ADYEN_RETURNURL,
    MAX_QTY_FOR_PRODUCT,
    PROJECT_SETTINGS,
    BRX_LOCALE, MAX_GIFTCARDS_IN_CART,
    BRX_PRODUCTS_URL,
    TRUSTGUARDIAN_ENABLED
} from "./APIUtils";
import {BannerContext} from "./banner.context";
import {RESTContext} from "./rest.context";
import {GeneralContext} from "./general.context";
import {LoaderContext} from "./loader.context";
import {getAttributeFromProductProjection, getCurrentPrice} from "../components/bundle/utils"

export const CartContext = React.createContext<ICartContext>(null!)

export const CartContextComponent = (props: any) => {
    const [isCartChanged, setIsCartChanged] = React.useState(false)
    const [maxGiftCardsReached, setMaxGiftCardsReached] = React.useState(false)
    const [skuForPopup, setSkuForPopup] = React.useState<string>("")
    const [externalPopup, setExternalPopup] = React.useState<boolean>(false)
    const [giftPromoId, setGiftPromoId] = React.useState<string>('')
    const [promoError, setPromoError] = React.useState<any>("")
    const [showError, setShowError] = React.useState<boolean>(false)
    const [processingOrder, setProcessingOrder] = React.useState(false)
    const [loadingGiftCardsMovements, setLoadingGiftCardsMovements] = React.useState(true)
    const [selectedGiftcards, setSelectedGiftcards] = React.useState([])
    const [selectedShippingMethod, setSelectedShippingMethod] = React.useState(null)
    const alertConsumer = React.useContext(AlertContext)
    const loaderConsumer = React.useContext(LoaderContext)
    const bannerConsumer = React.useContext(BannerContext)
    const {ajax$, ajaxMiddleware$, AWSlogger, token} = React.useContext(RESTContext)
    const {
        userData,
        wishlist,
        shippingMethods,
        setShippingMethods,
        setCurrentOrder,
        setWishlist,
        setOrders,
        cart,
        setCart,
        setTemporaryOrder,
        klarna,
        setKlarna,
        payU,
        giftCards,
        setGiftCards,
        giftCardsMovements,
        setGiftcardMovements,
        setPayU,
        younited,
        setYounited,
        setYounitedUri,
        buyBox,
        setBuyBox,
        setPayFastSignature
    } = React.useContext(GeneralContext)
    const {t} = React.useContext(LabelContext)

    React.useEffect(() => {
        if (cart) {
            checkExternalPromoAppliedAndValidateCart(cart)
            getAvailableGiftSkuInCart(cart)
        }
    }, [cart])

    const hasServicesInCart = cart?.lineItems.filter(item => item.productType.obj?.key === "services").length > 0
    const numberOfProducts = cart?.lineItems.filter(item => item.productType.obj?.key !== "services").reduce((sum, item) => sum + item.quantity, 0)
    const isEmptyCart = (numberOfProducts === 0)
    const numberOfProductsWish = wishlist?.lineItems.length
    const isEmptyWish = (numberOfProductsWish === 0)
    const servicesPrices = getPrices(cart, true)
    const productsPrices = getPrices(cart, false)
    const savingTotalPrice = createPrice((productsPrices.totalPrice.centAmount + servicesPrices.totalPrice.centAmount) - (productsPrices.totalPriceDiscounted.centAmount + servicesPrices.totalPriceDiscounted.centAmount), cart?.totalPrice.currencyCode)

    const emptyShippingAddress = {
        country: PROJECT_COUNTRY,
        custom: {
            type: {
                typeId: "type",
                key: "address-billing-info"
            },
            fields: {
                internalType: "placeholder"
            }
        }
    }

    //quando hai un prodotto e un omaggio a carrello e rimuovi il prodotto -> rimuovo l'omaggio dal carrello
    const getAvailableGiftSkuInCart = (cart: ICart) => {
        let promoInsideCart: string[] = []

        cart.lineItems.filter((item: any) => {
            return item && item["discountedPrice"] && item["discountedPrice"]["includedDiscounts"] && item["discountedPrice"]["includedDiscounts"].length > 0
        }).map((_item: any) => {
            return _item["discountedPrice"]["includedDiscounts"]
        }).forEach((item: any) => {
            item.forEach((__item: any) => {
                if (__item['discount'] && __item['discount']['obj'] &&
                    __item['discount']['obj']['custom'] && __item['discount']['obj']['custom']['fields'] &&
                    __item['discount']['obj']['custom']['fields']['category-of-gift']) {
                    promoInsideCart.push(__item['discount']['id'])
                }
            })
        })

        let _promoInsideCart = promoInsideCart.filter((v, i, a) => a.indexOf(v) === i)
        let changeLineItemAction: { action: string; lineItemId: any; quantity: any; }[] = []

        cart.lineItems.forEach((lineItem: any) => {
            if (lineItem.productType.obj &&
                lineItem.productType.obj.key === 'EXTERNAL-PRODUCT' &&
                lineItem && lineItem.custom &&
                lineItem.custom.fields &&
                lineItem.custom.fields['parent-promo'] &&
                !_promoInsideCart.includes(lineItem.custom.fields['parent-promo'])) {
                changeLineItemAction.push({action: 'changeLineItemQuantity', lineItemId: lineItem.id, quantity: 0})
            }
        })

        if (Object.keys(changeLineItemAction).length > 0) {
            const body = {
                version: cart.version,
                actions: changeLineItemAction
            }
            ajax$(calls.Project.updateCart(cart.id), undefined, body).subscribe(res => {
                setCart(res)
            })
        }
    }

    //stabilisce se aprire il popup o no
    const checkExternalPromoAppliedAndValidateCart = (cart: ICart) => {
        setExternalPopup(false)
        let arr: string [] = []
        cart.lineItems.forEach((item: any) => {
            const discounts = item && item["discountedPrice"] && item["discountedPrice"]["includedDiscounts"] && item["discountedPrice"]["includedDiscounts"].length > 0
            if (discounts) {
                item["discountedPrice"]["includedDiscounts"].forEach((_item: any) => {
                    if (_item['discount'] &&
                        _item['discount']['obj'] &&
                        _item['discount']['obj']['custom'] &&
                        _item['discount']['obj']['custom']['fields'] &&
                        _item['discount']['obj']['custom']['fields']['category-of-gift']) {
                        arr.push(_item['discount']['id'] + ':' + _item['discount']['obj']['custom']['fields']['category-of-gift']['id'])
                    }
                })
            }
        })

        let uniqueArr = arr.filter((v, i, a) => a.indexOf(v) === i)

        let promoAndCategory: any[] = []
        uniqueArr.forEach((el) => {
            let promoId = el.split(":")[0]
            let catId = el.split(":")[1]
            let json = {
                promoId: promoId,
                categoryId: catId,
                countProduct: 0,
                countPromo: 0
            }
            promoAndCategory.push(json)
        })

        let qtaProductGroup1 = 0, qtaProductGroup2 = 0;
        for (let i = 0; i < promoAndCategory.length; i++) {
            //conto i prodotti in promozione a carrello
            cart.lineItems.forEach((item: any) => {
                const discounts = item && item["discountedPrice"] && item["discountedPrice"]["includedDiscounts"] && item["discountedPrice"]["includedDiscounts"].length > 0
                if (discounts && promoAndCategory[i].promoId) {
                    item["discountedPrice"]["includedDiscounts"].forEach((_item: any) => {
                        if (_item["discount"]["id"] === promoAndCategory[i].promoId) {
                            if (item["productKey"].indexOf("ECF01") === 0) {
                                qtaProductGroup1 += item.quantity
                            } else if (item["productKey"].indexOf("CGF01") === 0) {
                                qtaProductGroup2 += item.quantity
                            }
                            promoAndCategory[i].countProduct += item.quantity
                        }
                    })
                }
            })

            //casistica per Italia: quando la promozione si attiva con due prodotti a carrello
            if (PROJECT_COUNTRY === "IT") {
                promoAndCategory[i].countProduct = qtaProductGroup1 < qtaProductGroup2 ? qtaProductGroup1 : qtaProductGroup2
            }

            cart.lineItems.forEach((lineItem: any) => {
                if (lineItem && lineItem.custom &&
                    lineItem.custom.fields &&
                    lineItem.custom.fields['parent-promo'] &&
                    lineItem.custom.fields['parent-promo'] === promoAndCategory[i].promoId) {
                    promoAndCategory[i].countPromo += lineItem.quantity
                }
            })
        }

        for (let i = 0; i < promoAndCategory.length; i++) {
            if (promoAndCategory[i].countProduct > 0 && promoAndCategory[i].countPromo !== promoAndCategory[i].countProduct) {
                setGiftPromoId(promoAndCategory[i].promoId)
                setExternalPopup(true)
            }
        }
    }

    //gestisce le modifiche delle quantitá degli omaggi aggiunti a carrello
    const updateExternalPromoToCart = (promo: any) => {
        AWSlogger({
            "type": "log",
            "action": "updateExternalPromoToCart",
            "promo": promo.toString(),
            "cartId": cart.id,
            "browserInfo": getBrowserInfo()
        })

        let addLineitemAction: any[] = []
        let changeLineItemAction: any[] = []
        let setCustomTypePromoIdAction: any[] = []

        promo.forEach((p: any) => {
            //prodotto esistente a carrello -> aggiorno qta
            if (p.lineitemId !== "") {
                changeLineItemAction.push({action: 'changeLineItemQuantity', lineItemId: p.lineitemId, quantity: p.qta})
            }

            //prodotto non esistente a carrello -> inserisco a carrello e setto custom type con promoId padre
            if (p.lineitemId === "" && p.qta > 0) {
                addLineitemAction.push(
                    {
                        action: 'addLineItem',
                        sku: p.key,
                        quantity: p.qta,
                        custom: {
                            type: {
                                typeId: "type",
                                key: "lineitem-parent-promo"
                            },
                            fields: {
                                "parent-promo": p.promoId
                            }
                        }
                    }
                )
            }
        })

        if (Object.keys(addLineitemAction).length > 0 && Object.keys(changeLineItemAction).length > 0) {
            const body = {
                version: cart.version,
                actions: addLineitemAction.concat(changeLineItemAction).concat(setCustomTypePromoIdAction)
            }
            ajax$(calls.Project.updateCart(cart.id), undefined, body).subscribe(res => {
                setCart(res);
            });
        } else if (Object.keys(changeLineItemAction).length > 0) {
            const body = {
                version: cart.version,
                actions: changeLineItemAction.concat(setCustomTypePromoIdAction)
            }
            ajax$(calls.Project.updateCart(cart.id), undefined, body).subscribe(res => {
                setCart(res);
            });
        } else if (Object.keys(addLineitemAction).length > 0) {
            const body = {
                version: cart.version,
                actions: addLineitemAction.concat(setCustomTypePromoIdAction)
            }
            ajax$(calls.Project.updateCart(cart.id), undefined, body).subscribe(res => {
                setCart(res);
            });
        }
    }

    const getOrders = () => {
        ajax$(calls.Project.getOrders())
            .subscribe(
                res => {
                    setOrders(res);
                }
            );
    };


    const getWishlist = (anonymousId: string = "") => {
        ajax$(calls.Project.getWishlist())
            .pipe(
                tap(
                    res => {
                        if (res.results.length > 0) {
                            let wishlistX = res.results[0];
                            setWishlist(wishlistX);

                        } else {
                            let body: any = {"name": {}};
                            body["name"][PROJECT_LANGUAGE] = "wishlist";
                            if (anonymousId)
                                body["anonymousId"] = anonymousId
                            ajax$(calls.Project.createWishlist(), undefined, body).subscribe(res => setWishlist(res))
                        }
                    }
                )
            ).subscribe();
    };

    const cartHavePaymentSuccess = (cart: ICart) => {
        let withPaymentSuccess = cart.paymentInfo?.payments.find(payment => payment.obj.transactions.filter((transaction: any) => (transaction.state === 'Success' && transaction.type === 'Authorization')).length > 0)
        return withPaymentSuccess ? 1 : 0
    }

    const createCartIfCurrentHavePaymentSuccess = () => {
        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {
                let havePayment = cartHavePaymentSuccess(active_cart)
                if (havePayment) {
                    ajax$(calls.Project.createCart(), undefined, {
                        inventoryMode: PROJECT_SETTINGS.inventoryMode,
                        currency: PROJECT_CURRENCY,
                        locale: PROJECT_LANGUAGE,
                        custom: {
                            "type": {
                                "typeId": "type",
                                "key": "invoice-request"
                            }
                        }
                    }).subscribe(
                        res => {
                            setCart(res);
                            getWishlist(res.anonymousId)
                        }
                    )
                }
            }
        )
    }

    const getActiveCart = () => {
        ajax$(calls.Project.getActiveCart())
            .pipe(
                tap(
                    (res) => {
                        if (!res.custom?.type) {
                            const body = {
                                version: res.version,
                                actions: [
                                    {
                                        action: "setCustomType",
                                        "type": {
                                            "typeId": "type",
                                            "key": "invoice-request"
                                        }
                                    }
                                ]
                            }
                            ajax$(calls.Project.updateCart(res.id), undefined, body).subscribe(
                                res_ => {
                                    setCart(res_);
                                    getWishlist(res_.anonymousId)
                                }
                            )
                        } else {
                            setCart(res);
                            getWishlist()
                        }
                    },
                    err => ajax$(calls.Project.createCart(), undefined, {
                        inventoryMode: PROJECT_SETTINGS.inventoryMode,
                        currency: PROJECT_CURRENCY,
                        locale: PROJECT_LANGUAGE,
                        custom: {
                            "type": {
                                "typeId": "type",
                                "key": "invoice-request"
                            }
                        }
                    }).subscribe(
                        res => {
                            setCart(res);
                            getWishlist(res.anonymousId)
                        }
                    )
                )
            ).subscribe();
    };

    const removeServiceLineItem = (id: string) => {
        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {

                const body = {
                    version: active_cart.version,
                    actions: [{
                        action: "removeLineItem",
                        lineItemId: id
                    }]
                }

                ajax$(calls.Project.updateCart(active_cart?.id), undefined, body)
                    .subscribe(res => setCart(res));
            }
        )
    };

    const addServiceLineItem = (sku: string, referenceProductId: string) => {

        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {
                let currentLineItem = active_cart.lineItems.find((line: any) => (line.productId === referenceProductId));
                let newQty = 1;
                if (currentLineItem !== undefined) {
                    // Ricerca righe conservizi correlati al prodotto
                    newQty = currentLineItem.quantity
                }
                const body = {
                    version: active_cart.version,
                    actions: [{
                        action: "addLineItem",
                        sku: sku,
                        quantity: newQty,
                        custom: {
                            type: {
                                typeId: "type",
                                key: "lineitemService"
                            },
                            fields: {
                                "relatedLineItem": {
                                    typeId: "product",
                                    id: referenceProductId
                                }
                            }
                        }
                    }]
                }
                ajax$(calls.Project.updateCart(active_cart?.id), undefined, body)
                    .subscribe(res => {
                        setCart(res);
                    });
            }
        )
    };

    const checkIsCartChange = (p_cart: any) => {
        let changedLines = p_cart.lineItems.filter((lineItem: any) => {
            if(["PL"].includes(PROJECT_COUNTRY) && PROJECT_SETTINGS.inventoryMode === "TrackOnly"){
                return false
            } else return (((lineItem.quantity > lineItem.variant.availability.availableQuantity) || (lineItem.quantity > MAX_QTY_FOR_PRODUCT)) && PROJECT_SETTINGS.inventoryMode === "ReserveOnOrder");
        });
        setIsCartChanged(changedLines.length > 0)
    }

    const checkGiftCardMaxQtaReached = (cart: ICart) => {
        let giftCardNumber = cart.lineItems.reduce((total: any, lineItem: ILineItem) => {
            if(lineItem.productType.obj.key === 'GIFTCARD'){
                return total + lineItem.quantity;
            }
            return total;
        }, 0)
        setMaxGiftCardsReached(giftCardNumber > MAX_GIFTCARDS_IN_CART)
    }


    const moveToWishlist = (idLineItem: string) => {
        let lineItem = cart.lineItems.find(lineItem => lineItem.id === idLineItem);
        if (lineItem) {
            removeLineItem(lineItem?.id, true);
            addRemoveWishlistLineItem(lineItem?.variant.sku)
        }
    }

    const addRemoveWishlistLineItem = (sku: string) => {
        let currentLineItem = wishlist.lineItems.find(item => item.variant && item.variant.sku === sku)
        let body: {}
        if (currentLineItem) {
            body = {
                version: wishlist.version,
                actions: [{
                    action: "removeLineItem",
                    lineItemId: currentLineItem.id
                }]
            }
        } else {
            body = {
                version: wishlist.version,
                actions: [{
                    action: "addLineItem",
                    sku: sku,
                    quantity: 1
                }]
            }
        }
        ajax$(calls.Project.updateWishlist(wishlist?.id), undefined, body)
            .subscribe(res => {
                setWishlist(res);
            });
    }


    const addLineItem = (sku: string, skipShowPopup: boolean = false, skipShowCartLayer: boolean = false, qta: number = 1) => {
        ajax$(calls.Project.getProduct(sku)).subscribe(
            res => {
                if (res.productType.obj.key === "BUNDLE") {
                    let skuList = getAttributeFromProductProjection(res, "bundle")

                    ajax$(calls.Project.getActiveCart()).subscribe(
                        active_cart => {
                            // RIMOSSO - Aggiungo dei bundle solo i prodotti che non sono a carrello
                            skuList.forEach((item: any) => {
                                let product_sku = item.obj.masterData.current.masterVariant.sku;
                                if (product_sku !== undefined) {
                                    addLineItem_Product(product_sku, skipShowPopup, skipShowCartLayer, false, sku);
                                }
                            });
                        }
                    )
                } else {
                    addLineItem_Product(sku, skipShowPopup, skipShowCartLayer, true, undefined, qta);
                }
            }
        )
    };

    const addLineItem_Product = (sku: string, skipShowPopup: boolean = false, skipShowCartLayer: boolean = false, async: boolean, bundleKey = "", qta: number = 1) => {

        ajax$(calls.Project.getActiveCart(), undefined, undefined, async).subscribe(
            active_cart => {

                let body = {"version": active_cart.version, "actions": [{}]}

                if (bundleKey) {
                    body = {
                        "version": active_cart.version,
                        "actions": [{
                            action: "addLineItem",
                            sku: sku,
                            quantity: 1,
                            custom: {
                                type: {
                                    typeId: "type",
                                    key: "lineitem-bundle"
                                },
                                fields: {
                                    "lineitem-bundle": bundleKey
                                }
                            }
                        }
                        ]
                    }
                } else {
                    body = {
                        "version": active_cart.version,
                        "actions": [{
                            action: "addLineItem",
                            sku: sku,
                            quantity: qta
                        }
                        ]
                    }
                }


                let showMessageOnAddToCart
                let disableMessageOnAddToCart
                ajax$(calls.Project.getProduct(sku || ""), undefined, undefined, async)
                    .subscribe((res: any) => {

                        //controllo se bisogna attivare il popup
                        showMessageOnAddToCart = res.masterVariant.attributes.find((attr: any) => attr.name === "show-message-on-add-to-cart")?.value
                        disableMessageOnAddToCart = res.masterVariant.attributes.find((attr: any) => attr.name === "disable-message-on-add-to-cart")?.value

                        let family = res.masterVariant.attributes.find((attr: any) => attr.name === "family")?.value?.key
                        let price = getCurrentPrice(res.masterVariant.prices, userData)?.value
                        let quantity = 1
                        let aesthetics = res.masterVariant.attributes.find((attr: any) => attr.name === "NEW_AESTHETICS")?.value.label

                        dataLayer.push({
                            event: 'addToCart',
                            ecommerce: {
                                currencyCode: `${price.currencyCode}`,
                                add: {
                                    products: [{
                                        name: `${res.name?.[PROJECT_LANGUAGE]}`,
                                        id: `${sku}`,
                                        price: `${parsePrice(price, true)}`,
                                        brand: `${aesthetics}`,
                                        category: `${family}`,
                                        variant: `${sku}`,
                                        quantity: `${quantity}`
                                    }]
                                }
                            }
                        });

                        if (!skipShowPopup && showMessageOnAddToCart && !disableMessageOnAddToCart) {
                            setSkuForPopup(sku)
                        } else {
                            /* Ricerca di Servizi correlati al carrello per adeguare la QTA */
                            /* Ricerca prodotto già presente a carrello */

                            let currentLineItem = active_cart.lineItems.find((line: any) => (line.variant.sku === sku));

                            if (currentLineItem !== undefined) {
                                // Ricerca righe con servizi correlati al prodotto
                                let newQty = currentLineItem.quantity + 1
                                let currentRelatedLineItems = active_cart.lineItems.filter((line: any) => (line.custom?.fields?.relatedLineItem?.id === currentLineItem?.productId));

                                currentRelatedLineItems.forEach((serviceLineItem: any) => {
                                    let actionServiceItem = {
                                        action: "changeLineItemQuantity",
                                        lineItemId: serviceLineItem.id,
                                        sku: serviceLineItem.variant.sku,
                                        quantity: newQty
                                    }
                                    body["actions"].push(actionServiceItem)
                                })
                            }

                            ajax$(calls.Project.updateCart(active_cart?.id), undefined, body, async)
                                .subscribe(res => {
                                    setCart(res);
                                    AWSlogger({
                                        "type": "log",
                                        "action": "AddLineItem",
                                        "email": res?.customerEmail,
                                        "browserInfo": getBrowserInfo(),
                                        "cartId": res?.id,
                                        "anonymousCartId": res?.anonymousId,
                                        "sku": sku
                                    })
                                    if(window.connectif?.managed?.isInitialized()){
                                        const products: any [] = []
                                        let totalQuantity = 0
                                        res.lineItems.map((line: ILineItem) => {
                                            products.push({
                                                quantity: line.quantity,
                                                price: line.totalPrice.centAmount / 100,
                                                productId: line.productKey,
                                                productDetailUrl:  BRX_PRODUCTS_URL + line.productKey,
                                                name: line.productKey,
                                                unitPrice: line.price.value.centAmount / 100
                                            })
                                            totalQuantity += line.quantity
                                          })
                                        const cart = {
                                            cartId: res.id,
                                            totalQuantity: totalQuantity,
                                            totalPrice: res.totalPrice.centAmount / 100,
                                            products: products
                                        };
                                        window.connectif.managed.sendEvents([
                                        ], { entityInfo: {
                                            primaryKey: res.customerEmail || '',
                                            _name: userData?.firstName,
                                            _surname: userData?.lastName
                                        }, cart });
                                    }
                                });
                            !skipShowCartLayer && openCartLayer();
                            //---------------
                        }
                    })
            }
        )
    };

    const removeLineItem = (id: string, deleteRow: boolean, quantity: number = 1) => {
        // Se la line item è di un Bundle riduco tutti i prodotti del bundle

        let lineToRemove: any = cart.lineItems.find(lineItem => lineItem.id === id)
        if (lineToRemove) {
            if (lineToRemove.custom?.fields["lineitem-bundle"]) {
                let activeBundleKey = lineToRemove.custom?.fields["lineitem-bundle"]
                let bundleLineItemsId = cart.lineItems.filter(lineItem => lineItem.custom?.fields["lineitem-bundle"] === activeBundleKey).map(lineItem => lineItem.id)
                removeLineItem_Product(bundleLineItemsId, deleteRow, quantity)

            } else {
                removeLineItem_Product([id], deleteRow, quantity)
            }
        }
    }

    const removeLineItem_Product = (ids: string[], deleteRow: boolean, quantity: number = 1) => {

        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {

                let actions: any[] = [];
                let body = {"version": active_cart.version, "actions": actions}

                ids.forEach(id => {
                    if (deleteRow) {
                        body["actions"].push({
                            action: "removeLineItem",
                            lineItemId: id
                        })
                    } else {
                        body["actions"].push({
                            action: "removeLineItem",
                            lineItemId: id,
                            quantity: quantity
                        });
                    }

                    /* Ricerca di Servizi correlati al carrello per adeguare la QTA */
                    /* Ricerca prodotto già presente a carrello */

                    let currentLineItem = active_cart.lineItems.find((line: any) => (line.id === id));

                    if (currentLineItem !== undefined) {
                        // Ricerca righe conservizi correlati al prodotto
                        let newQty = currentLineItem.quantity - 1
                        let currentRelatedLineItems = active_cart.lineItems.filter((line: any) => (line.custom?.fields?.relatedLineItem?.id === currentLineItem?.productId));

                        currentRelatedLineItems.forEach((serviceLineItem: any) => {
                            if (!deleteRow) {
                                let actionServiceItem = {
                                    action: "changeLineItemQuantity",
                                    lineItemId: serviceLineItem.id,
                                    sku: serviceLineItem.variant.sku,
                                    quantity: newQty
                                }
                                body["actions"].push(actionServiceItem)
                            } else {
                                let actionServiceItem = {
                                    action: "removeLineItem",
                                    lineItemId: serviceLineItem.id,
                                }
                                body["actions"].push(actionServiceItem)
                            }
                        })
                    }

                    let dl_quantity = deleteRow ? currentLineItem.quantity : 1;

                    let family = currentLineItem.variant.attributes.find((attr: any) => attr.name === "family")?.value.key
                    let aesthetics = currentLineItem.variant.attributes.find((attr: any) => attr.name === "NEW_AESTHETICS")?.value.label

                    dataLayer.push({
                        event: 'removeFromCart',
                        ecommerce: {
                            currencyCode: `${currentLineItem.price.value.currencyCode}`,
                            remove: {
                                products: [{
                                    name: `${currentLineItem?.name?.[PROJECT_LANGUAGE]}`,
                                    id: `${currentLineItem?.productSlug?.[PROJECT_LANGUAGE]}`,
                                    price: `${parsePrice(currentLineItem?.price?.value, true)}`,
                                    brand: `${aesthetics}`,
                                    category: `${family}`,
                                    variant: `${currentLineItem?.productSlug?.[PROJECT_LANGUAGE]}`,
                                    quantity: `${dl_quantity}`,
                                }]
                            }
                        }
                    })
                })

                ajax$(calls.Project.updateCart(active_cart?.id), undefined, body)
                    .subscribe(res => {
                        setCart(res)
                        if(window.connectif?.managed?.isInitialized()){
                            const products: any[] = []
                            let totalQuantity = 0
                            res.lineItems.map((line: ILineItem) => {
                                products.push({
                                    quantity: line.quantity,
                                    price: line.totalPrice.centAmount / 100,
                                    productId: line.productKey,
                                    productDetailUrl:  BRX_PRODUCTS_URL + line.productKey,
                                    name: line.productKey,
                                    unitPrice: line.price.value.centAmount / 100
                                })
                                totalQuantity += line.quantity
                                })
                            const cart = {
                                cartId: res.id,
                                totalQuantity: totalQuantity,
                                totalPrice: res.totalPrice.centAmount / 100,
                                products: products
                            };
                            window.connectif.managed.sendEvents([
                            ], { entityInfo: {
                                primaryKey: res.customerEmail || ''
                            }, cart });
                        }
                    });


            }
        )
    };

    const recalculate = (cart: any) => {
        const body: any = {
            version: cart.version,
            actions: [
                {
                    action: "recalculate",
                    "updateProductData": true
                }
            ]
        }

        ajax$(calls.Project.updateCart(cart?.id), undefined, body)
            .subscribe(
                res => setCart(res),
            );
    };

    const addPromo = (id: string) => {
        const body = {
            version: cart.version,
            actions: [{
                action: "addDiscountCode",
                code: id
            }]
        }
        ajax$(calls.Project.updateCart(cart?.id), undefined, body)
            .subscribe(
                res => {
                    setCart(res);
                    setShowError(false);
                    AWSlogger({
                        "type": "log",
                        "action": "addDiscountCode",
                        "discountCodeId": id,
                        "cartId": cart?.id,
                        "browserInfo": getBrowserInfo()
                    })
                },
                err => {
                    let errMsg = err.response?.errors[0]?.code
                    if (err.response?.errors[0]?.reason) {
                        errMsg += "_" + err.response?.errors[0]?.reason
                    }
                    if (err.response?.errors[0]?.message) {
                        let msg = err.response?.errors[0]?.message
                        if (msg.includes('is not active')) {
                            errMsg = "NotActive"
                        }
                        if (msg.includes('not contain active and valid')) {
                            errMsg = "NotValid"
                        }
                    }
                    setPromoError(errMsg);
                    setShowError(true);
                }
            );
    };

    const removePromo = (id: string) => {
        const body = {
            version: cart.version,
            actions: [{
                action: "removeDiscountCode",
                discountCode: id
            }]
        }
        ajax$(calls.Project.updateCart(cart?.id), undefined, body)
            .subscribe(res => setCart(res));
    };

    const setAddress = (address: any, type: EAddressType, cart_: ICart = cart) => {

        /* LOGGER */
        AWSlogger({
            "type": "log",
            "action": ESetAddress[type],
            "cartId": cart_.id,
            "email": cart_.customerEmail,
            "address": address,
            "browserInfo": getBrowserInfo()
        })

        const body = {
            "version": cart_.version,
            "actions": [] as any
        }

        body["actions"].push({
            "action": "recalculate",
            "updateProductData": true
        })

        body["actions"].push({
            "action": ESetAddress[type],
            "address": address
        })

        if (type === EAddressType.Shipping) {
            body["actions"].push({
                    "action": "setShippingMethod",
                }
            )
        }
        return ajax$(calls.Project.updateCart(cart_.id), undefined, body)
            .pipe(
                tap(res => {
                    setCart(res)
                    AWSlogger({
                        "type": "log",
                        "action": ESetAddress[type] + " done",
                        "cartId": res.id,
                        "email": res.customerEmail,
                        "address": address,
                        "browserInfo": getBrowserInfo()
                    })
                })
            )
    }

    const loadShippingMethods = () => {
        ajax$(calls.Project.shippingMethods(cart.id))
            .subscribe(
                res => {
                    let giftCardShippingMethod = res.results?.filter((shippingMethod: any) => shippingMethod.key === "giftcard")
                    if(giftCardShippingMethod.length > 0){
                        setShippingMethods(giftCardShippingMethod)
                    }else{
                        setShippingMethods(res.results)
                    }
                },
                err => setAddress(emptyShippingAddress, EAddressType.Shipping).subscribe()
            );
    }


    const setSendInvoice = (send: boolean) => {
        /* LOGGER */
        AWSlogger({
            "type": "log",
            "action": "setInvoice",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "state": send,
            "browserInfo": getBrowserInfo()
        })

        const body = {
            "version": cart.version,
            "actions": [] as any
        }
        body["actions"].push({
            "action": "setCustomField",
            "name": "requested-invoice",
            "value": send
        })

        body["actions"].push({
            "action": "recalculate",
            "updateProductData": true
        });

        ajax$(calls.Project.updateCart(cart.id), undefined, body)
            .subscribe(
                res => setCart(res),
            );
    }

    const setNlConsent = (send: boolean) => {
        /* LOGGER */
        AWSlogger({
            "type": "log",
            "action": "setNlConsent",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "state": send,
            "browserInfo": getBrowserInfo()
        })

        const body = {
            "version": cart.version,
            "actions": [] as any
        }
        body["actions"].push({
            "action": "setCustomField",
            "name": "subscribed",
            "value": send
        })

        ajax$(calls.Project.updateCart(cart.id), undefined, body)
            .subscribe(
                res => setCart(res),
            );
    }

    const setConsentThrdParty = (consent: boolean) => {
        /* LOGGER */
        AWSlogger({
            "type": "log",
            "action": "setInvoice",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "state": consent,
            "browserInfo": getBrowserInfo()
        })

        const body = {
            "version": cart.version,
            "actions": [] as any
        }
        body["actions"].push({
            "action": "setCustomField",
            "name": "third-party-consent",
            "value": consent
        })

        body["actions"].push({
            "action": "recalculate",
            "updateProductData": true
        });

        ajax$(calls.Project.updateCart(cart.id), undefined, body)
            .subscribe(
                res => setCart(res),
            );
    }


    /*
    1. Ricava indirizzo Store
    2. Imposta come indirizzo di spedizione
    3. Imposta store come metodo di spedizione
    */

    const setPickupInStore = (shippingMethodId: string) => {
        ajax$(calls.Project.getShippingMethod(shippingMethodId), undefined, null)
            .subscribe(
                res => {

                    let storeAddress = {
                        firstName: res.name,
                        country: PROJECT_COUNTRY,
                        streetName: res.custom.fields.streetName,
                        streetNumber: res.custom.fields.streetNumber,
                        postalCode: res.custom.fields.postalCode,
                        city: res.custom.fields.city,
                        region: res.custom.fields.region,
                        custom: {
                            type: {
                                typeId: "type",
                                key: "address-billing-info"
                            },
                            fields: {
                                internalType: "store"
                            }
                        }
                    }

                    const body = {
                        "version": cart.version,
                        "actions": [
                            {
                                "action": ESetAddress["shipping"],
                                "address": storeAddress
                            },
                            {
                                "action": "setShippingMethod",
                                "shippingMethod": {
                                    "id": shippingMethodId,
                                    "typeId": "shipping-method"
                                }
                            }
                        ]
                    }
                    ajax$(calls.Project.updateCart(cart.id), undefined, body)
                        .subscribe(res => {
                            setCart(res);
                        });
                }
            );
    }

    const resetCartForCheckout = (addressTemp?: any, forceResetShippingAddress?: boolean) => {
        AWSlogger({
            "type": "log",
            "action": "resetCartForCheckout",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "browserInfo": getBrowserInfo()
        })

        ajax$(calls.Project.shippingMethods(cart.id))
            .subscribe(
                (res: any) => {
                    let body: any = {
                        "version": cart.version,
                        "actions": []
                    }

                    const isShippingMethodValid = res?.results?.find((validShippingMethods: any) => {
                        return validShippingMethods.id === cart.shippingInfo?.shippingMethod?.id
                    })

                    if(!isShippingMethodValid || forceResetShippingAddress){
                        let shippingAddress = emptyShippingAddress;
                        if (addressTemp)
                            shippingAddress = addressTemp;

                        body["actions"].push(
                            {
                                "action": "setShippingMethod"
                            },
                            {
                                "action": ESetAddress["shipping"],
                                "address": shippingAddress
                            }
                        )
                    }


                    if (!cart.customerEmail && userData?.email) {
                        let actionMail = {
                            action: "setCustomerEmail",
                            email: userData?.email
                        }

                        body["actions"].push(actionMail)
                    }


                    let actionLocale = {
                        action: "setLocale",
                        locale: PROJECT_LANGUAGE
                    }
                    body["actions"].push(actionLocale)

                    if (BRX_LOCALE === "nl_NL" || BRX_LOCALE === "de_DE") {
                        let actionSetSendInvoice = {
                            action: "setCustomField",
                            name: "requested-invoice",
                            value: true
                        }
                        body["actions"].push(actionSetSendInvoice)
                    }

                    // Rimozione condici non validi
                    cart.discountCodes.filter((code) => {
                        return (code.state !== "MatchesCart")
                    }).map(
                        (code) => {
                            let actionRemoveCode = {
                                "action": "removeDiscountCode",
                                "discountCode": {
                                    "typeId": "discount-code",
                                    "id": code.discountCode.id
                                }
                            }

                            body["actions"].push(actionRemoveCode)
                        }
                    )

                    ajax$(calls.Project.updateCart(cart.id), undefined, body)
                        .subscribe(
                            res => {
                                setCart(res)
                                AWSlogger({
                                    "type": "log",
                                    "action": "resetCartForCheckout done",
                                    "cartId": res.id,
                                    "email": res.customerEmail,
                                    "browserInfo": getBrowserInfo()
                                })
                            },
                            err => {
                                AWSlogger({
                                    "type": "error",
                                    "action": "resetCartForCheckout error",
                                    "error": err,
                                    "browserInfo": getBrowserInfo()
                                })
                            }
                        );
                }
            );
    }


    const setShippingMethod = (id: string) => {
        /* LOGGER */
        AWSlogger({
            "type": "log",
            "action": "setShippingMethod",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "shippingMethod": id,
            "browserInfo": getBrowserInfo()
        })

        const body = {
            "version": cart.version,
            "actions": [
                {
                    "action": "setShippingMethod",
                    "shippingMethod": {
                        "id": id,
                        "typeId": "shipping-method"
                    },
                },
                {
                    "action": "recalculate",
                    "updateProductData": true
                }
            ]
        }
        ajax$(calls.Project.updateCart(cart.id), undefined, body)
            .subscribe(
                res => {
                    setCart(res)
                    AWSlogger({
                        "type": "log",
                        "action": "setShippingMethod done",
                        "cartId": res.id,
                        "email": res.customerEmail,
                        "shippingMethod": id,
                        "browserInfo": getBrowserInfo()
                    })
                }
            );
    }

    const setNoteOnCart = (note: string) => {
        /* LOGGER */
        AWSlogger({
            "type": "log",
            "action": "setOrderNote",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "note": note,
            "browserInfo": getBrowserInfo()
        })

        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {
                const body = {"version": active_cart.version, "actions": [] as any}

                body["actions"].push({
                    "action": "setCustomField",
                    "name": "orderNote",
                    "value": note
                });

                body["actions"].push({
                    "action": "recalculate",
                    "updateProductData": true,
                });

                ajax$(calls.Project.updateCart(active_cart.id), undefined, body)
                    .subscribe(res => setCart(res))
            }
        )
    }

    const setSubscriptionOnCart = (subscribe: boolean) => {
        /* LOGGER */
        AWSlogger({
            "type": "log",
            "action": "setSubscriptionOnCart",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "subscribe": subscribe,
            "browserInfo": getBrowserInfo()
        })

        const body = {"version": cart.version, "actions": [] as any}

        body["actions"].push({
                "action": "setCustomField",
                "name": "subscribed",
                "value": subscribe
            }
        );

        ajax$(calls.Project.updateCart(cart.id), undefined, body)
            .subscribe(res => setCart(res),);
    }

    const additionalsDetailsPayment = (data: any, component: any) => {
        setProcessingOrder(true)
        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {
                AWSlogger({
                    "type": "log",
                    "action": "additionalsDetailsPayment",
                    "data": data,
                    "cartId": active_cart.id,
                    "browserInfo": getBrowserInfo()
                })
                let lastPayment = active_cart.paymentInfo.payments[active_cart.paymentInfo.payments.length - 1].obj
                const body = {
                    "version": lastPayment.version,
                    "actions": [
                        {
                            "action": "setCustomField",
                            "name": "submitAdditionalPaymentDetailsRequest",
                            "value": JSON.stringify(data)
                        }
                    ]
                }
                ajax$(calls.Project.updatePayment(lastPayment.id), undefined, body)
                    .subscribe(
                        payment_res => {
                            let paymentDetailsResponse = JSON.parse(payment_res.custom.fields.submitAdditionalPaymentDetailsResponse.replace('\"', '"'))
                            if (paymentDetailsResponse.resultCode === "Authorised" && payment_res.id === paymentDetailsResponse.merchantReference) {
                                createOrder(active_cart, component, true)
                            } else {
                                AWSlogger({
                                    "type": "log",
                                    "action": "additionalsDetailsPayment_UpdatePayment",
                                    "cartId": active_cart.id,
                                    "paymentId": payment_res.id,
                                    "browserInfo": getBrowserInfo()
                                })
                                bannerConsumer.show({
                                    type: paymentDetailsResponse.resultCode,
                                    description: paymentDetailsResponse.resultCode
                                })
                                setProcessingOrder(false)
                            }
                        }
                    )
            }
        )
    }

    // Handle redirect
    // https://docs.adyen.com/online-payments/3d-secure/redirect-3ds2-3ds1/web-component#handle-the-redirect

    const createOrder = (cart: ICart, component: any, retry: boolean, paymentStatus?: string) => {
        AWSlogger({
            "type": "log",
            "action": "TryCreateOrder",
            "cartId": cart.id,
            "email": cart.customerEmail,
            "browserInfo": getBrowserInfo()
        })

        const body: any = {
            "id": cart.id,
            "version": cart.version
        }

        loaderConsumer.show();
        ajax$(calls.Project.createOrder(), undefined, body)
            .subscribe(
                res_order => {
                    setCurrentOrder(res_order);
                    getActiveCart()
                    AWSlogger({
                        "type": "log",
                        "action": "OrderCreated",
                        "orderId": res_order.id,
                        "orderNumber": res_order.orderNumber,
                        "cartId": res_order.cart.id,
                        "email": res_order.customerEmail,
                        "browserInfo": getBrowserInfo()
                    })

                },
                err => {
                    let error_log = {
                        type: "orderCreation",
                        ctResult: err
                    }
                    const errStatus = err?.status
                    const errCode = err?.response?.errors[0]?.code
                    if ((errStatus && errStatus === 400 && errCode && errCode === 'InvalidOperation') || (errStatus && errStatus === 409 && errCode && errCode === 'ConcurrentModification')){
                        // check if order was already created
                        const body = {
                            cartId: cart.id,
                            projectKey: PROJECT_KEY,
                            authToken: token?.access_token
                        }
                        ajaxMiddleware$(calls.Project.getOrderFromCartId(), undefined, body)
                            .subscribe(
                                res => {
                                    if(res.code === 200 && res.obj){
                                        setCurrentOrder(res.obj);
                                        getActiveCart()
                                        AWSlogger({
                                            "type": "log",
                                            "action": "OrderCreatedExternally",
                                            "orderId": res.obj.id,
                                            "orderNumber": res.obj.orderNumber,
                                            "cartId": res.obj.cart.id,
                                            "email": res.obj.customerEmail,
                                            "browserInfo": getBrowserInfo()
                                        })
                                    }else {
                                        AWSlogger({
                                            "type": "error",
                                            "action": "createOrder",
                                            "cartId": cart.id,
                                            "email": cart.customerEmail,
                                            "response": error_log,
                                            "browserInfo": getBrowserInfo()
                                        })
                                        setTemporaryOrder(cart);
                                        createCartIfCurrentHavePaymentSuccess();
                                        component.unmount()
                                    }
                                }
                            )
                         
                    }
                    else if (errStatus && (errStatus === 502 || errStatus === 504) && errCode && (errCode === 'ExtensionBadResponse' || errCode === 'ExtensionNoResponse') && retry) {
                        setTimeout(() => {
                            //qua proviamo a ricreare l'ordine ma potrebbe succedere che nel frattempo l'ordine viene creato dalla lambda AdyenNotification e riceviamo
                            //(ConcurrentModification) perche la versione del carrello é cambiata
                            createOrder(cart, component, false)
                        }, 2000)
                    } else {
                        // Non mostro più errore, ma landing dedicata
                        setTemporaryOrder(cart);
                        createCartIfCurrentHavePaymentSuccess();
                        component.unmount()
                        
                        AWSlogger({
                            "type": "error",
                            "action": "createOrder",
                            "cartId": cart.id,
                            "email": cart.customerEmail,
                            "response": error_log,
                            "browserInfo": getBrowserInfo()
                        })
                    }
                },
                () => loaderConsumer.hide()
            )

    }

    const createOrderWithoutPayment = (paymentName: string) => {
        // Create payment
        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {
                let customerName = "";
                if (userData) {
                    customerName = (userData?.firstName ?? "") + " " + (userData?.lastName ?? "");
                }

                const actions = [
                    {
                        "action": "setCustomField",
                        "name": "paymentName",
                        "value": paymentName
                    }
                ]

                if (customerName && customerName.trim() !== "") {
                    actions.push({
                        "action": "setCustomField",
                        "name": "customerName",
                        "value": customerName
                    });
                }


                const body = {
                    version: active_cart.version,
                    actions: actions
                };

                ajax$(calls.Project.updateCart(active_cart.id), undefined, body)
                    .subscribe(
                        res_cart => {
                            createOrder(res_cart, null, true)
                        }, err => {
                            AWSlogger({
                                "type": "error",
                                "action": "createOrderWithoutPayment",
                                "response": err,
                                "showPopup": "yes",
                                "browserInfo": getBrowserInfo()
                            })
                            alertConsumer.show({type: err.response})
                        })
            }, err => {
                AWSlogger({
                    "type": "error",
                    "action": "createOrderWithoutPayment",
                    "response": err,
                    "showPopup": "yes",
                    "browserInfo": getBrowserInfo()
                })
                alertConsumer.show({type: err.response})
        })
    }

    const createPayment = (state: any, component: any, adyen: any, is_paypal: boolean, paymentName: string = "cc") => {
        // Create payment
        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {
                const bodyPayment = {
                    amountPlanned: {currencyCode: PROJECT_CURRENCY, centAmount: active_cart.totalPrice.centAmount},
                    paymentMethodInfo: {paymentInterface: "ctp-adyen-integration"},
                    custom: {
                        type: {typeId: "type", key: "ctp-adyen-integration-web-components-payment-type"},
                        fields: {adyenMerchantAccount: ADYEN_MERCHANTACCOUNT, commercetoolsProjectKey: PROJECT_KEY}
                    }
                }

                ajax$(calls.Project.createPayment(), undefined, bodyPayment)
                    .subscribe(
                        async res_payment => {
                            // inzio chiamata payment

                            let customerName = "";
                            if (userData) {
                                customerName = (userData?.firstName ?? "") + " " + (userData?.lastName ?? "");
                            }
                            let ipAddressRes;
                            try{
                                ipAddressRes = await ajax$(calls.Project.getIpAddress(token?.access_token)).toPromise()
                            } catch (err){
                                console.error(err)
                            }
                            const ipAddress = ipAddressRes?.obj?.ipAddress?.split(',')[0].trim()
                            const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                            const shopperIp = ipv4Regex.test(ipAddress) ? ipAddress : null

                            // 1. Aggiungi pagamento al carrello + Aggiunta nome utente
                            const actions = [
                                {"action": "addPayment", "payment": {type: "payment", id: res_payment.id}},
                                {
                                    "action": "setCustomField",
                                    "name": "paymentName",
                                    "value": paymentName
                                }
                            ];

                            if (customerName && customerName.trim() !== "") {
                                actions.push({
                                    "action": "setCustomField",
                                    "name": "customerName",
                                    "value": customerName
                                })
                            }
                            
                            if (shopperIp && TRUSTGUARDIAN_ENABLED) {
                                actions.push({
                                    "action": "setCustomField",
                                    "name": "shopperIp",
                                    "value": shopperIp
                                })
                            }

                            const body = {
                                version: active_cart.version,
                                actions: actions
                            }

                            ajax$(calls.Project.updateCart(active_cart.id), undefined, body).subscribe(
                               res_cart => {
                                    setCart(res_cart)
                                    let billingAddress = getBillingAddress(res_cart)
                                    let shippingAddress = getShippingAddress(res_cart)
                                    let dayOfBirth = (userData && userData['dateOfBirth']) ? userData['dateOfBirth'] : ""
                                    // 2. Update Payment
                                    const makePaymentRequestData : any = {
                                        "amount": {
                                            "currency": PROJECT_CURRENCY,
                                            "value": res_cart.totalPrice.centAmount
                                        },
                                        "reference": res_payment.id,
                                        "paymentMethod": (is_paypal ? {
                                            "type": 'paypal',
                                            "subtype": 'sdk'
                                        } : state.data?.paymentMethod),
                                        "browserInfo": state.data?.browserInfo,
                                        "additionalData": {
                                            "allow3DS2": true
                                        },
                                        "channel": "Web",
                                        "countryCode": PROJECT_COUNTRY,
                                        "shopperLocale": BRX_LOCALE,
                                        "shopperEmail": res_cart.customerEmail,
                                        "shopperName": {
                                            "firstName": active_cart['billingAddress']['firstName'] || "",
                                            "lastName": active_cart['billingAddress']['lastName'] || ""
                                        },
                                        "telephoneNumber": active_cart['billingAddress']['mobile'] || "",
                                        "dateOfBirth": dayOfBirth,
                                        "origin": BRX_BASE_URL,
                                        "returnUrl": ADYEN_RETURNURL + "?checkresult=" + active_cart.id,
                                        "merchantAccount": ADYEN_MERCHANTACCOUNT,
                                        "billingAddress": billingAddress,
                                        "deliveryAddress": shippingAddress,
                                        "lineItems": res_cart.lineItems.map((lineItem: any) => {
                                            return createLineItemForKlarna(lineItem, active_cart)
                                        })
                                    }
                                    if (shopperIp){
                                        makePaymentRequestData.shopperIP = shopperIp
                                    }
                                    const body = {
                                        "version": res_payment.version,
                                        "actions": [
                                            {
                                                "action": "setCustomField",
                                                "name": "makePaymentRequest",
                                                "value": JSON.stringify(makePaymentRequestData)
                                            }
                                        ]
                                    }

                                    ajax$(calls.Project.updatePayment(res_payment.id), undefined, body)
                                        .subscribe(
                                            payment_res => {

                                                let make_payment_response_txt = payment_res.custom.fields.makePaymentResponse.replace('\"', '"')
                                                let make_payment_response = JSON.parse(make_payment_response_txt)
                                                if (make_payment_response.action) {
                                                    if (is_paypal) {
                                                        adyen.handleAction(make_payment_response.action);
                                                    } else {
                                                        adyen.createFromAction(make_payment_response.action, {size: '05'}).mount('#action-container');
                                                    }
                                                    return;
                                                }

                                                //if (payment_res.transactions[0].state == "Success") {
                                                if (make_payment_response.resultCode === "Authorised") {
                                                    createOrder(res_cart, component, true)
                                                } else {
                                                    let paymentResponse = JSON.parse(payment_res?.custom?.fields?.makePaymentResponse);

                                                    AWSlogger({
                                                        "type": "error",
                                                        "action": "makePayment",
                                                        "cartId": active_cart.id,
                                                        "email": active_cart.customerEmail,
                                                        "response": paymentResponse,
                                                        "browserInfo": getBrowserInfo()
                                                    })


                                                    component.unmount();
                                                    let title_error = t("ecommerce.error.payment-generic-error-title");
                                                    let description_error = t("ecommerce.error.payment-generic-error-description");
                                                    switch (paymentResponse.refusalReason) {
                                                        case "CVC Declined":
                                                            title_error = t("ecommerce.error.payment-cvc-declined-title");
                                                            description_error = t("ecommerce.error.payment-cvc-declined-description");
                                                            break;
                                                    }
                                                    bannerConsumer.show({
                                                        type: title_error,
                                                        description: description_error
                                                    })
                                                }

                                            },
                                            err => {
                                                // updatePayment Error
                                                AWSlogger({
                                                    "type": "error",
                                                    "action": "updatePayment",
                                                    "cartId": active_cart.id,
                                                    "email": active_cart.customerEmail,
                                                    "response": err,
                                                    "showPopup": "yes",
                                                    "browserInfo": getBrowserInfo()
                                                })

                                                alertConsumer.show({type: err.response})
                                                setProcessingOrder(false)
                                            }
                                        );
                                },
                                err => {
                                    // AddPayment Error
                                    AWSlogger({
                                        "type": "error",
                                        "action": "addPayment",
                                        "cartId": active_cart.id,
                                        "email": active_cart.customerEmail,
                                        "response": err,
                                        "showPopup": "yes",
                                        "browserInfo": getBrowserInfo()
                                    })
                                    alertConsumer.show({type: err.response})
                                    setProcessingOrder(false)
                                }
                            )

                        },
                        err => {
                            AWSlogger({
                                "type": "error",
                                "action": "createPayment",
                                "response": err,
                                "showPopup": "yes",
                                "browserInfo": getBrowserInfo()
                            })
                            // Create Payment Error
                            alertConsumer.show({type: err.response})
                            setProcessingOrder(false)
                        }
                    )

                //  fine chiamat payment
            },
            err => {
                AWSlogger({
                    "type": "error",
                    "action": "createPayment",
                    "response": err,
                    "showPopup": "yes",
                    "browserInfo": getBrowserInfo()
                })
                alertConsumer.show({type: err.response})
                setProcessingOrder(false)
            }
        )

    }

    const getKlarna = (cartId: string) => {
        const body = {
            cartId: cartId,
            projectKey: PROJECT_KEY,
            authToken: token?.access_token
        }
        ajaxMiddleware$(calls.Project.klarnaStart(), undefined, body)
            .subscribe(
                res => {
                    setKlarna(res);
                },
                err => {
                    AWSlogger({
                        "type": "error",
                        "action": "getKlarna",
                        "cartId": cartId,
                        "response": err,
                        "browserInfo": getBrowserInfo()
                    })
                }
            );
    }

    const getKlarnaCreate = (orderId: string) => {
        setKlarna(undefined);
        const body = {
            klarnaOrderId: orderId,
            projectKey: PROJECT_KEY,
            authToken: token?.access_token
        }
        ajaxMiddleware$(calls.Project.klarnaCreate(), undefined, body)
            .subscribe(
                res => {
                    setKlarna(res);
                }
            );
    }


    /**/

    const createLineItemForKlarna = (lineItem: any, active_cart: ICart) => {
        const line: any = {}
        const unitPrice = lineItem.price.value.centAmount
        const taxRate = lineItem.taxRate.amount * 10000

        let discountedPrice = lineItem.price.value.centAmount
        if (lineItem.discountedPrice && lineItem.discountedPrice.value) {
            discountedPrice = lineItem.discountedPrice.value.centAmount
        } else if (lineItem.price.discounted && lineItem.price.discounted.value) {
            discountedPrice = lineItem.price.discounted.value.centAmount
        }
        const total_amount = discountedPrice
        const total_discount_amount = (unitPrice - discountedPrice) * lineItem.quantity

        const totalTaxAmount = Math.round(total_amount - (total_amount * 10000) / (10000 + taxRate))

        line['description'] = lineItem.productKey
        line['id'] = lineItem.productKey
        line['quantity'] = lineItem.quantity
        line['taxPercentage'] = taxRate
        line['amountIncludingTax'] = total_amount
        line['amountExcludingTax'] = total_amount - totalTaxAmount
        line['taxAmount'] = totalTaxAmount

        return line
    }

    const getBillingAddress = (active_cart: ICart) => {
        const data: any = {}
        let s_address = active_cart['billingAddress']

        data['city'] = s_address['city']
        data['country'] = s_address['country']
        if (s_address['streetNumber']) {
            data['houseNumberOrName'] = s_address['streetNumber']
        } else {
            data['houseNumberOrName'] = '1'
        }
        data['postalCode'] = s_address['postalCode']
        data['street'] = s_address['streetName']

        return data
    }

    const getShippingAddress = (active_cart: ICart) => {
        const data: any = {}
        let s_address = active_cart['shippingAddress']

        data['city'] = s_address['city']
        data['country'] = s_address['country']
        if (s_address['streetNumber']) {
            data['houseNumberOrName'] = s_address['streetNumber']
        } else {
            data['houseNumberOrName'] = '1'
        }
        data['postalCode'] = s_address['postalCode']
        data['street'] = s_address['streetName']

        return data
    }


  const startPayU = (cartId: string, isRatyPay: boolean) => {
    const body = {
      cartId: cartId,
      projectKey: PROJECT_KEY,
      authToken: token?.access_token,
      isRatyPay
    }
    ajaxMiddleware$(calls.Project.payUStart(), undefined, body)
      .subscribe(
        res => {
          setPayU(res);
        },
        err => {
          AWSlogger({
            "type": "error",
            "action": "startPayU",
            "cartId": cartId,
            "response": err,
            "browserInfo": getBrowserInfo()
          })
        }
      );
  }

  const getPayUCreate = (cartId: string) => {
    setPayU(undefined);
    const body = {
      cartId: cartId,
      projectKey: PROJECT_KEY,
      authToken: token?.access_token
    }
    ajaxMiddleware$(calls.Project.payUCreate(), undefined, body)
      .subscribe(
        res => {
          setPayU(res);
        },
        err => {
          AWSlogger({
            "type": "error",
            "action": "getPayUCreate",
            "cartId": cartId,
            "response": err,
            "browserInfo": getBrowserInfo()
          })
        }
      );
  }

    const loadGiftCards = (onlyActive: boolean = true) => {
        //solo per utenti loggati
        if(userData){
            const authToken = token?.access_token || "";
            let completedCalls = 0;

            ajaxMiddleware$(calls.Project.getGiftCards(authToken, onlyActive)).subscribe(res => {
                const giftCards = res?.obj || [];
                setGiftCards(giftCards);

                if (giftCards.length === 0) {
                    setLoadingGiftCardsMovements(false);
                }

                giftCards.map((giftcard: any) => {
                    const body = {
                        authToken: token?.access_token || "",
                        projectKey: PROJECT_KEY,
                    }

                    ajaxMiddleware$(calls.Project.getCustomerGiftCardMovements(giftcard.id), undefined, body).subscribe(res => {
                        setGiftcardMovements((prevData: any) => ({
                            ...prevData,
                            [giftcard.id]: res.movements
                        }));

                        completedCalls++;
                        if (completedCalls === giftCards.length) {
                            setLoadingGiftCardsMovements(false);
                        }
                    });
                });
            });
        }
    };

    const populateGiftCardRecipientInfo = (data: any) => {
        const giftCardIds = Object.keys(data["gift-card"]);

        let dataRecipient = ""
        let recipentActions = giftCardIds.map((giftCardId: any) => {
            dataRecipient = JSON.stringify(data["gift-card"][giftCardId])

            return ({
                action: "setLineItemCustomType",
                lineItemId: giftCardId,
                type: {
                    key: "lineitem-giftcard",
                    typeId: "type"
                },
                fields: {
                    "recipients-giftcard": dataRecipient
                }
            })
        })

        ajax$(calls.Project.getActiveCart()).subscribe(
            active_cart => {
                const body = {
                    version: active_cart.version,
                    actions: recipentActions
                }

                ajax$(calls.Project.updateCart(active_cart?.id), undefined, body)
                    .subscribe(res => {
                        setCart(res);
                    });
            }
        )
    }

    const setApplyGiftCardToCart = (giftCard: any) => {
        const body = {
            cartId: cart?.id,
            serialNumber: giftCard?.value,
            projectKey: PROJECT_KEY,
            authToken: token?.access_token || ""
        }

        ajaxMiddleware$(calls.Project.applyGiftCardToCart(), undefined, body)
            .subscribe(
                res => {
                    if(res.code === 200) {
                        setCart(res?.obj)
                        const selectedGiftCard: any = [];
                        res?.obj.customLineItems.map((item: any) => {
                            selectedGiftCard.push({ value: item.slug.split('gift-card-')[1], label: item.name[Object.keys(item.name)[0]], residual: Math.abs(item.money.centAmount) })
                        });
                        setSelectedGiftcards(selectedGiftCard)
                    }
                },
            );
    }
    const setRemoveGiftCardFromCart = (giftCard: any) => {
        const body = {
            cartId: cart?.id,
            customLineItemId: giftCard?.id,
            projectKey: PROJECT_KEY,
            authToken: token?.access_token || ""
        }

        ajaxMiddleware$(calls.Project.removeGiftCardFromCart(), undefined, body)
            .subscribe(
                res => {
                    if(res.code === 200) {
                        setCart(res?.obj)
                        const selectedGiftCard: any = [];
                        res?.obj.customLineItems.map((item: any) => {
                            selectedGiftCard.push({ value: item.slug.split('gift-card-')[1], label: item.name[Object.keys(item.name)[0]], residual: Math.abs(item.money.centAmount) })
                        });
                        setSelectedGiftcards(selectedGiftCard)
                    }
                },
            );
    }

    const setRemoveAllGiftCardFromCart = (cart: ICart) => {
        if(cart?.customLineItems?.length > 0) {
            const body = {
                cartId: cart?.id,
                projectKey: PROJECT_KEY,
                authToken: token?.access_token || ""
            }

            ajaxMiddleware$(calls.Project.removeAllGiftCardsFromCart(), undefined, body)
                .subscribe(
                    res => {
                        if(res.code === 200) {
                            setCart(res?.obj)
                            const selectedGiftCard: any = [];
                            res?.obj.customLineItems.map((item: any) => {
                                selectedGiftCard.push({ value: item.slug.split('gift-card-')[1], label: item.name[Object.keys(item.name)[0]], residual: Math.abs(item.money.centAmount) })
                            });
                            setSelectedGiftcards(selectedGiftCard)
                        }
                    },
                );
        }
    }

    const differenceBy = (a: any, b: any) => {
        //const a = [{ value: "4a55eff3-1e0d-4a81-9105-3ddd7521d642", display: "Jamsheer" }, { value: "644838b3-604d-4899-8b78-09e4799f586f", display: "Muhammed" }, { value: "b6ee537a-375c-45bd-b9d4-4dd84a75041d", display: "Ravi" }, { value: "e97339e1-939d-47ab-974c-1b68c9cfb536", display: "Ajmal" }, { value: "a63a6f77-c637-454e-abf2-dfb9b543af6c", display: "Ryan" }];
        //const b = [{ value: "4a55eff3-1e0d-4a81-9105-3ddd7521d642", display: "Jamsheer", $$hashKey: "008" }, { value: "644838b3-604d-4899-8b78-09e4799f586f", display: "Muhammed", $$hashKey: "009" }, { value: "b6ee537a-375c-45bd-b9d4-4dd84a75041d", display: "Ravi", $$hashKey: "00A" }, { value: "e97339e1-939d-47ab-974c-1b68c9cfb536", display: "Ajmal", $$hashKey: "00B" }];

        // A comparer used to determine if two entries are equal.
        const isSameUser = (a: any, b: any) => a.value === b.value && a.display === b.display;

        // Get items that only occur in the left array,
        // using the compareFunction to determine equality.
        const onlyInLeft = (left: any, right: any, compareFunction: any) =>
            left.filter((leftValue: any) =>
                !right.some((rightValue: any) =>
                    compareFunction(leftValue, rightValue)));

        const onlyInA = onlyInLeft(a, b, isSameUser);
        const onlyInB = onlyInLeft(b, a, isSameUser);

        return ([...onlyInA, ...onlyInB][0]);
    }

    const getYounitedOffers = (cartId: string, totalAmount: number) => {
        const body = {
            cartId: cartId,
            totalAmount: totalAmount,
            projectKey: PROJECT_KEY,
            authToken: token?.access_token
        }
        ajaxMiddleware$(calls.Project.younitedOffers(), undefined, body)
            .subscribe(
                res => {
                    setYounited(res);
                },
                err => {
                    AWSlogger({
                        "type": "error",
                        "action": "getYounitedOffers",
                        "cartId": cartId,
                        "response": err,
                        "browserInfo": getBrowserInfo()
                    })
                }
            );
    }


  const initYounitedContract = (cartId: string, maturity: number, phone: string) => {
    //setYounited(undefined);
    const body = {
      cartId: cartId,
      projectKey: PROJECT_KEY,
      maturity: maturity,
      phoneNumber: phone,
      authToken: token?.access_token
    }
    ajaxMiddleware$(calls.Project.younitedInit(), undefined, body)
      .subscribe(
        res => {
          setYounitedUri(res.obj);
        },
        err => {
          AWSlogger({
            "type": "error",
            "action": "initYounitedContract",
            "cartId": cartId,
            "response": err,
            "browserInfo": getBrowserInfo()
          })
          alertConsumer.show({type:{
            errors: [
                {
                    code: err.response?.message
                }
            ]
          }})
        }
      );
  }

  const confirmYounitedContract = (cartId: string) => {
    setYounited(undefined);
    const body = {
      cartId: cartId,
      projectKey: PROJECT_KEY,
      authToken: token?.access_token
    }
    ajaxMiddleware$(calls.Project.younitedConfirm(), undefined, body)
      .subscribe(
        res => {
          setYounited(res);
        },
        err => {
          AWSlogger({
            "type": "error",
            "action": "confirmYounitedContract",
            "cartId": cartId,
            "response": err,
            "browserInfo": getBrowserInfo()
          })
        }
      );
  }

  const createPayPalOrder = (cartId: string) => {
    const body = {
        cartId: cartId,
        projectKey: PROJECT_KEY,
        authToken: token?.access_token
      }
      return ajaxMiddleware$(calls.Project.payPalCreate(), undefined, body).toPromise()
  }

  const capturePayPalOrder = (cartId: string, paypalOrderId: string) => {
    const body = {
        cartId: cartId,
        projectKey: PROJECT_KEY,
        paypalOrderId,
        authToken: token?.access_token
      }
      return ajaxMiddleware$(calls.Project.payPalCapture(), undefined, body).toPromise()
  }

  const getSelectedShippingMethod = (cart: ICart, shippingMethods: any) => {
        const {id} = cart?.shippingInfo?.shippingMethod || {};
        return id ? shippingMethods?.find((store: any) => store.id === id) : null;
  };

  const initBuyBoxPayment = (cartId: string) => {
    setBuyBox(undefined);
    const body = {
      cartId: cartId,
      projectKey: PROJECT_KEY,
      authToken: token?.access_token
    }
    ajaxMiddleware$(calls.Project.buyBoxInit(), undefined, body)
      .subscribe(
        res => {
          setBuyBox(res);
        },
        err => {
          AWSlogger({
            "type": "error",
            "action": "initBuyBoxPayment",
            "cartId": cartId,
            "response": err,
            "browserInfo": getBrowserInfo()
          })
        }
      );
  }

  const confirmBuyBoxPayment = (cartId: string, buyBoxToken: string, payerId: string) => {
    setBuyBox(undefined);
    const body = {
      cartId: cartId,
      projectKey: PROJECT_KEY,
      authToken: token?.access_token,
      payerId: payerId,
      token: buyBoxToken
    }
    ajaxMiddleware$(calls.Project.buyBoxConfirm(), undefined, body)
      .subscribe(
        res => {
          setBuyBox(res);
        },
        err => {
          AWSlogger({
            "type": "error",
            "action": "confirmBuyBoxPayment",
            "cartId": cartId,
            "response": err,
            "browserInfo": getBrowserInfo()
          })
        }
      );
  }

    const generatePayFastSignature = (cartId: string) => {
        const body = {
            cartId: cart.id,
            projectKey: PROJECT_KEY,
            authToken: token?.access_token
        }
        ajaxMiddleware$(calls.Project.payFastGenerateSignature(), undefined, body)
            .subscribe(
                res => {
                    if(res.code === 200){
                        setPayFastSignature(res.obj.signature)
                    }
                },
                err => {
                    AWSlogger({
                        "type": "error",
                        "action": "getPayFastSignature",
                        "cartId": cartId,
                        "response": err,
                        "browserInfo": getBrowserInfo()
                    })
                }
            );
    }


    React.useEffect(() => {
    if (cart) {
      loadShippingMethods();
      createCartIfCurrentHavePaymentSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);


    return (
        <CartContext.Provider value={{
            addLineItem,
            addServiceLineItem,
            removeLineItem,
            removeServiceLineItem,
            addPromo,
            removePromo,
            getActiveCart,
            setAddress,
            loadShippingMethods,
            shippingMethods,
            setShippingMethod,
            setNoteOnCart,
            resetCartForCheckout,
            hasServicesInCart,
            numberOfProducts,
            numberOfProductsWish,
            isEmptyWish,
            productsPrices,
            servicesPrices,
            savingTotalPrice,
            isEmptyCart,
            addRemoveWishlistLineItem,
            setSendInvoice,
            wishlist,
            setPickupInStore,
            createPayment,
            getOrders,
            setSubscriptionOnCart,
            additionalsDetailsPayment,
            isCartChanged,
            checkIsCartChange,
            createCartIfCurrentHavePaymentSuccess,
            recalculate,
            moveToWishlist,
            klarna,
            getKlarna,
            getKlarnaCreate,
            setSkuForPopup,
            skuForPopup,
            setConsentThrdParty,
            checkExternalPromoAppliedAndValidateCart,
            updateExternalPromoToCart,
            externalPopup,
            setExternalPopup,
            giftPromoId,
            setGiftPromoId,
            promoError,
            showError,
            setShowError,
            processingOrder,
            setProcessingOrder,
            setNlConsent,
            startPayU,
            payU,
            getPayUCreate,
            giftCards,
            setGiftCards,
            giftCardsMovements,
            setGiftcardMovements,
            loadingGiftCardsMovements,
            loadGiftCards,
            setApplyGiftCardToCart,
            setRemoveGiftCardFromCart,
            setRemoveAllGiftCardFromCart,
            differenceBy,
            populateGiftCardRecipientInfo,
            createOrderWithoutPayment,
            younited,
            getYounitedOffers,
            initYounitedContract,
            confirmYounitedContract,
            createPayPalOrder,
            capturePayPalOrder,
            selectedGiftcards,
            setSelectedGiftcards,
            checkGiftCardMaxQtaReached,
            buyBox,
            initBuyBoxPayment,
            confirmBuyBoxPayment,
            maxGiftCardsReached,
            getSelectedShippingMethod,
            generatePayFastSignature,
            selectedShippingMethod,
            setSelectedShippingMethod
        }}>{props.children}
        </CartContext.Provider>
    );
};
