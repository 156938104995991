import React from "react";
import {EShippingType, ICheckoutContext} from "../common/common.models";
import {
    ADYEN_CLIENT_KEY,
    ADYEN_ENVIRONMENT,
    ADYEN_LOCALE,
    ADYEN_PAYMENTMETHODS, ADYEN_PAYMENTMETHODSCONFIGURATION,
    calls, PAYPAL_CLIENT_ID,
    PROJECT_COUNTRY,
    PROJECT_CURRENCY, PROJECT_KEY
} from "./APIUtils"
import {AWSlogger, RESTContext} from "./rest.context"
import {getBrowserInfo, getCartInfo} from "../common/constants"
import AdyenCheckout from "@adyen/adyen-web"
import {ILineItem} from "../common/cart.models"
import {GeneralContext} from "./general.context"
import {CartContext} from "./cart.context"
import {loadScript} from "@paypal/paypal-js"


export const CheckoutContext = React.createContext<ICheckoutContext>(null!);

export const CheckoutContextComponent = (props: any) => {
    const { ajax$, ajaxMiddleware$ } = React.useContext(RESTContext)
    const { cart, userData, setCurrentOrder } = React.useContext(GeneralContext)
    const {
        createPayment,
        additionalsDetailsPayment,
        createPayPalOrder,
        capturePayPalOrder,
        createOrderWithoutPayment,
        startPayU,
        initYounitedContract,
        initBuyBoxPayment,
        generatePayFastSignature
    } = React.useContext(CartContext)

    // ------- STATI

    const [shippingType, setShippingType] = React.useState(EShippingType.Home);

    const [tempAddresses, setTempAddresses] = React.useState(null!)
    const [privacy, setPrivacy] = React.useState<any>(null!)
    const [consent, setConsent] = React.useState(null!)
    const [externalWarrantyCheck, setEternalWarrantyCheck] = React.useState(false)
    const [nlConsentValue, setNlConsentValue] = React.useState(false)

    const [commercial, setCommercial] = React.useState(false)
    const [activeStep, setActiveStep] = React.useState(0)
    const [focusPrivacy, setFocusPrivacy] = React.useState(false)
    const [consent3Party, setConsent3Party] = React.useState(false)
    const [clickedPay, setClickedPay] = React.useState(false)
    const [clickedPayWithPaypal, setClickedPayWithPaypal] = React.useState(false)
    const [paypalError, setPaypalError] = React.useState("")
    const [applepayEnabled, setApplepayDisabled] = React.useState(false)
    const [lineItemWithNoAvailability, setLineItemWithNoAvailability] = React.useState(false)

    //GIFTCARD STATES
    const [enabledGiftCardEditButton, setEnabledGiftCardEditButton] = React.useState(false)
    const [giftcardInsideCard, setGiftcardInsideCart] = React.useState(false)
    const [hasGiftCards, setHasGiftCards] = React.useState(false);
    const [showGiftCardSelect, setShowGiftCardSelect] = React.useState(false);

    const [sepaComponent, setSepaComponent] = React.useState<any>(null!)
    const [adyenComponent, setAdyenComponent] = React.useState<any>(null!)
    const [paypalComponent, setPaypalComponent] = React.useState<any>(null!)
    const [klarnaComponent, setKlarnaComponent] = React.useState<any>(null!)
    const [bancontactComponent, setBancontactComponent] = React.useState<any>(null!)
    const [applepayComponent, setApplepayComponent] = React.useState<any>(null!)
    const [idealComponent, setIdealComponent] = React.useState<any>(null!)
    const [showPaypal, setShowPaypal] = React.useState<boolean>(false)
    const [loadingPaypal, setLoadingPaypal] = React.useState<boolean>(false)
    const [checkoutStep, setCheckoutStep] = React.useState(1)
    const [makeSignUp, setMakeSignUp] = React.useState(false)
    const [useShippingAddressAsBilling, setUseShippingAddressAsBilling] = React.useState(true)

    //SHIPPING AND BILLING ADDRESS FORM
    const [addAddressFormVisibility, setAddAddressFormVisibility] = React.useState<{
        shipping: boolean;
        billing: boolean;
    }>({
        shipping: false,
        billing: false
    });

    const [isAddressEdit, setIsAddressEdit] = React.useState<{
        shipping: boolean;
        billing: boolean;
    }>({
        shipping: false,
        billing: false
    })

    const [selectedAddress, setSelectedAddress] = React.useState<{
        shipping: any | null;
        billing: any | null;
    }>({
        shipping: null,
        billing: null,
    });

    const [numberAddressAvailable, setNumberAddressAvailable] = React.useState<{
        shipping: number
        billing: number
    }>({
        shipping: 0,
        billing: 0
    })

    const [showListAddress, setShowListAddress] = React.useState<{
        shipping: boolean
        billing: boolean
    }>({
        shipping: false,
        billing: false
    })


    // ------- FUNZIONI DI UTILITY

    const setAddAddressFormVisibilityHandler = (type: 'shipping' | 'billing', visible: boolean) => {
        setAddAddressFormVisibility(prevState => {
            return {
                ...prevState,
                [type]: visible
            }
        })
    }

    const setIsAddressEditHandler = (type: 'shipping' | 'billing', value: boolean) => {
        setIsAddressEdit(prevState => ({
            ...prevState,
            [type]: value
        }))
    }

    const setSelectedAddressByTypeHandler = (type: 'shipping' | 'billing', address: any | null) => {
        setSelectedAddress(prevAddresses => ({
            ...prevAddresses,
            [type]: address,
        }))
    }

    const setNumberAddressAvailableHandler = (type: 'shipping' | 'billing', value: number) => {
        setNumberAddressAvailable(prevState => ({
            ...prevState,
            [type]: value
        }))
    }

    const setShowListAddressHandler = (type: 'shipping' | 'billing', show: boolean) => {
        setShowListAddress(prevState => ({
            ...prevState,
            [type]: show
        }))
    }

    const redirectToPayFast = () => {
        setClickedPay(true)
        generatePayFastSignature(cart.id, PROJECT_KEY)
    }

    const unmountAdyenComponents = () => {
        if (showPaypal) setShowPaypal(false)
        if (paypalComponent) paypalComponent.unmount()
        if (klarnaComponent) klarnaComponent.unmount()
        if (adyenComponent) adyenComponent.unmount()
        if (sepaComponent) sepaComponent.unmount()
        if (bancontactComponent) bancontactComponent.unmount()
        if (idealComponent) idealComponent.unmount()
        if (applepayComponent) applepayComponent.unmount()
    }

    const onAdditionalDetails = (state: any, component: any) => {
        additionalsDetailsPayment(state.data, component)
    }

    const handleOnChange = () => {}

    const hasExternalWarrantyService = cart?.lineItems.filter((item: ILineItem) => item.productType.obj?.key === "services" && item.productKey === "extra-warranty").length > 0

    const isMandatoryFieldsFlagged = () => {
        if(hasExternalWarrantyService){
            setFocusPrivacy(!(commercial && (privacy || userData) && externalWarrantyCheck))
            return commercial && (privacy || userData) && externalWarrantyCheck
        }else {
            setFocusPrivacy(!(commercial && (privacy || userData)))
            return commercial && (privacy || userData)
        }
    }

    // ------- LOAD PAYMENT CONFIGURATIONS

    const redirectToPayU = (isRatyPay: boolean) => {
        setClickedPay(true)
        startPayU(cart.id, isRatyPay)
    }

    const redirectToYounitedPay = (maturity: number, phone: string) => {
        setClickedPay(true)
        initYounitedContract(cart.id, maturity, phone)
    }

    const redirectToBuyBox = () => {
        setClickedPay(true)
        initBuyBoxPayment(cart.id)
    }

    const payWithBankTransfer = () => {
        setClickedPay(true)
        createOrderWithoutPayment("bankTransfer")
    }

    const payLaterWithPayByLink = () => {
        setClickedPay(true)
        createOrderWithoutPayment("payLater")
    }

    const closeOrderWithGiftCard = () => {
        setClickedPay(true)
        createOrderWithoutPayment("giftcard")
    }

    const loadIdealForm = () => {
        let checkout: any
        setClickedPay(true)
        ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
            AWSlogger({
                type: "log",
                action: "clickPaymentButton",
                cartId: active_cart.id,
                payment: "ideal",
                browserInfo: getBrowserInfo(),
                cart: getCartInfo(active_cart)
            })

            const idealConfiguration = {
                locale: ADYEN_LOCALE,
                environment: ADYEN_ENVIRONMENT,
                clientKey: ADYEN_CLIENT_KEY,
                amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
                countryCode: PROJECT_COUNTRY,
                paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
                onSubmit: (state: any, component: any) => {
                    createPayment(state, component, checkout, false, "ideal")
                },
                onAdditionalDetails: onAdditionalDetails,
                showPayButton: true,
            };

            checkout = await AdyenCheckout(idealConfiguration)
            unmountAdyenComponents()
            setIdealComponent(checkout.create('ideal', idealConfiguration).mount('#ideal-container'))
            setApplepayDisabled(false)
            const younitedComponent = document.getElementById("younited-close");
            if (younitedComponent) {
                younitedComponent.click();
            }
        })
    }

    const loadBancontactForm = () => {
        setClickedPay(true)
        ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
            AWSlogger({
                type: "log",
                action: "clickPaymentButton",
                cartId: active_cart.id,
                payment: "bancontact",
                browserInfo: getBrowserInfo(),
                cart: getCartInfo(active_cart)
            })

            const bancontactConfiguration = {
                locale: ADYEN_LOCALE,
                environment: ADYEN_ENVIRONMENT,
                clientKey: ADYEN_CLIENT_KEY,
                hasHolderName: true,
                holderNameRequired: true,
                billingAddressRequired: false, // Set to true to show the billing address input fields.
                enableStoreDetails: true,
                brands: ['bcmc','mc','visa'],
                showPayButton: true,
                autoFocus: true,
                setStatusAutomatically: true,
                onSubmit: (state: any, component: any) => {
                    createPayment(state, component, checkout, false, "bancontact")
                },
                onAdditionalDetails: onAdditionalDetails,
                onChange: handleOnChange,
            };

            const checkout = await AdyenCheckout(bancontactConfiguration)
            unmountAdyenComponents()
            setBancontactComponent(checkout.create('bcmc', bancontactConfiguration).mount('#bcmc-container'))
            setApplepayDisabled(false)
            const younitedComponent = document.getElementById("younited-close");
            if (younitedComponent) {
                younitedComponent.click();
            }
        })
    }

    const loadAdyenForm = () => {
        setClickedPay(true)
        ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
            AWSlogger({
                type: "log",
                action: "clickPaymentButton",
                cartId: active_cart.id,
                payment: "adyen",
                browserInfo: getBrowserInfo(),
                cart: getCartInfo(active_cart)
            })

            const translations = {
                "de_DE": {
                    "payButton": "Jetzt kostenpflichtig bestellen für",
                }
            };

            const adyen_configuration = {
                locale: ADYEN_LOCALE,
                environment: ADYEN_ENVIRONMENT,
                clientKey: ADYEN_CLIENT_KEY,
                paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
                paymentMethodsConfiguration: ADYEN_PAYMENTMETHODSCONFIGURATION,
                onAdditionalDetails: onAdditionalDetails,
                onSubmit: (state: any, component: any) => {
                    createPayment(state, component, checkout, false, "cc")
                },
                showPayButton: true,
                setStatusAutomatically: true,
                onChange: handleOnChange,
                amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
                translations: translations
            }
            const checkout = await AdyenCheckout(adyen_configuration)
            unmountAdyenComponents()
            setAdyenComponent(checkout.create("card", { hasHolderName: true, holderNameRequired: true }).mount("#adyen"))
            const younitedComponent = document.getElementById("younited-close");
            if (younitedComponent) {
                younitedComponent.click();
            }
            setApplepayDisabled(false)
        })
    }

    const loadApplePayForm = () => {
        setClickedPay(true)
        ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
            AWSlogger({
                type: "log",
                action: "clickPaymentButton",
                cartId: active_cart.id,
                payment: "applepay",
                browserInfo: getBrowserInfo(),
                cart: getCartInfo(active_cart)
            })

            const applepay_configuration = {
                locale: ADYEN_LOCALE,
                environment: ADYEN_ENVIRONMENT,
                clientKey: ADYEN_CLIENT_KEY,
                amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
                countryCode: PROJECT_COUNTRY,
                paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
                paymentMethodsConfiguration: ADYEN_PAYMENTMETHODSCONFIGURATION,
                onAdditionalDetails: onAdditionalDetails,
                onSubmit: (state: any, component: any) => {
                    createPayment(state, component, checkout, false, "applepay")
                },
            }
            const checkout = await AdyenCheckout(applepay_configuration)
            let comp = checkout.create('applepay')
            comp.isAvailable()
                .then(() => {
                    unmountAdyenComponents()
                    setApplepayComponent(comp.mount("#applepay-container"));
                    setApplepayDisabled(false)
                })
                .catch((e: any) => {
                    unmountAdyenComponents()
                    setApplepayDisabled(true)
                });

            const younitedComponent = document.getElementById("younited-close");
            if (younitedComponent) {
                younitedComponent.click();
            }
        })
    }

    const loadSepaForm = () => {
        setClickedPay(true)
        ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
            AWSlogger({
                type: "log",
                action: "clickPaymentButton",
                cartId: active_cart.id,
                payment: "sepa",
                browserInfo: getBrowserInfo(),
                cart: getCartInfo(active_cart)
            })

            const adyen_configuration = {
                locale: ADYEN_LOCALE,
                environment: ADYEN_ENVIRONMENT,
                clientKey: ADYEN_CLIENT_KEY,
                paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
                paymentMethodsConfiguration: ADYEN_PAYMENTMETHODSCONFIGURATION,
                onAdditionalDetails: onAdditionalDetails,
                onSubmit: (state: any, component: any) => {
                    createPayment(state, component, checkout, false, "sepa")
                },
                showPayButton: true,
                setStatusAutomatically: true,
                onChange: handleOnChange,
                amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
            }
            const checkout = await AdyenCheckout(adyen_configuration)
            unmountAdyenComponents()
            setSepaComponent(checkout.create("sepadirectdebit").mount("#sepadirectdebit"))
            setApplepayDisabled(false)
            const younitedComponent = document.getElementById("younited-close");
            if (younitedComponent) {
                younitedComponent.click();
            }
        })
    }

    const loadPaypalForm = () => {
        setClickedPay(true)
        ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
            AWSlogger({
                type: "log",
                action: "clickPaymentButton",
                cartId: active_cart.id,
                payment: "paypal",
                browserInfo: getBrowserInfo(),
                cart: getCartInfo(active_cart)
            })

            const adyen_configuration = {
                locale: ADYEN_LOCALE, // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/online-payments/components-web/localization-components.
                environment: ADYEN_ENVIRONMENT,
                clientKey: ADYEN_CLIENT_KEY,
                paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS }, // The payment methods response returned in step 1.
                onAdditionalDetails: onAdditionalDetails,
                onSubmit: (state: any, component: any) => {
                    createPayment(state, component, checkout, false, "paypal")
                },
                showPayButton: true,
                setStatusAutomatically: true,
                onChange: handleOnChange, // Your function for handling onChange event
                amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
            }
            const checkout = await AdyenCheckout(adyen_configuration)
            unmountAdyenComponents()

            let tmp_paypalComponent = checkout
                .create("paypal", {
                    environment: ADYEN_ENVIRONMENT, // Change this to "live" when you're ready to accept live PayPal payments
                    countryCode: PROJECT_COUNTRY, // Only needed for test. This will be automatically retrieved when you are in production.
                    intent: "authorize",
                    amount: {
                        currency: PROJECT_CURRENCY,
                        value: active_cart.totalPrice.centAmount,
                    },
                    onSubmit: (state: any, component: any) => {
                        // Your function calling your server to make the /payments request.
                        createPayment(state.data, component, component, true, "paypal")
                    },
                    onCancel: (data: any, component: any) => {
                        // Sets your prefered status of the component when a PayPal payment is cancelled. In this example, return to the initial state.
                        component.setStatus("ready")
                    },
                    onError: (error: any, component: any) => {
                        // Sets your prefered status of the component when an error occurs. In this example, return to the initial state.
                        component.setStatus("ready")
                    },
                    onAdditionalDetails: (state: any, component: any) => {
                        // Your function to submit a state.data object to the payments/details endpoint.
                        additionalsDetailsPayment(state.data, component)
                    },
                })
                .mount("#paypal-container")
            setPaypalComponent(tmp_paypalComponent);
            const younitedComponent = document.getElementById("younited-close");
            if(younitedComponent){
                younitedComponent.click();
            }
            setApplepayDisabled(false)
        })
    }

    const loadPaypalDirectForm = async () => {
        setClickedPay(true)
        let paypal: any

        try {
            paypal = await loadScript({
                clientId: PAYPAL_CLIENT_ID,
                currency: PROJECT_CURRENCY,
                enableFunding: "card",
                components: ["buttons","card-fields"]
            });
        } catch (error) {
            console.error("failed to load the PayPal JS SDK script", error);
        }

        if (paypal && !showPaypal) {
            ajax$(calls.Project.getActiveCart()).subscribe( async (active_cart) => {
                AWSlogger({
                    type: "log",
                    action: "clickPaymentButton",
                    cartId: active_cart.id,
                    payment: "paypal",
                    browserInfo: getBrowserInfo(),
                    cart: getCartInfo(active_cart)
                })
                try {
                    const cardField = await paypal.CardFields({
                        async createOrder() {
                            try {
                                const response = await createPayPalOrder(active_cart.id)

                                if (response.obj.orderData.id) {
                                    return response.obj.orderData.id;
                                } else {
                                    const errorDetail = response.obj.orderData?.details?.[0];
                                    const errorMessage = errorDetail
                                        ? `${errorDetail.issue} ${errorDetail.description} (${response.orderData.debug_id})`
                                        : JSON.stringify(response.obj.orderData);

                                    throw new Error(errorMessage);
                                }
                            } catch (error) {
                                console.error(error);
                                setPaypalError("Si è verificato un errore in fase di pagamento, si prega di riprovare");
                            }
                        },
                        async onApprove(data: any, actions: any) {
                            try {
                                const response = await capturePayPalOrder(active_cart.id, data.orderID)
                                // Three cases to handle:
                                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                //   (2) Other non-recoverable errors -> Show a failure message
                                //   (3) Successful transaction -> Show confirmation or thank you message

                                const errorDetail = response.obj.orderData?.details?.[0];

                                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                    // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                    // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                    return actions.restart();
                                } else if (errorDetail) {
                                    // (2) Other non-recoverable errors -> Show a failure message
                                    throw new Error(`${errorDetail.description} (${response.obj.orderData.debug_id})`);
                                } else if (!response.obj.orderData.purchase_units || !response.obj.ctOrder) {
                                    throw new Error(JSON.stringify(response.obj.orderData));
                                } else {
                                    // (3) Successful transaction -> Show confirmation or thank you message
                                    // Or go to another URL:  actions.redirect('thank_you.html');
                                    setCurrentOrder(response.obj.ctOrder)
                                }
                            } catch (error) {
                                console.error(error);
                                setPaypalError("Si è verificato un errore in fase di pagamento, si prega di riprovare");
                            }
                        },
                        async onError(error: any) {
                            // Handle the error object
                            console.error(error);
                            setPaypalError("Si è verificato un errore in fase di pagamento, si prega di riprovare");
                            setClickedPayWithPaypal(false);
                        },
                    })
                    unmountAdyenComponents()
                    setShowPaypal(true)
                    setLoadingPaypal(true)
                    if (cardField.isEligible()) {
                        const nameField = cardField.NameField({
                            placeholder: "Nome sulla carta"
                        });
                        nameField.render("#card-name-field-container");

                        const numberField = cardField.NumberField({
                            placeholder: "Numero carta"
                        });
                        numberField.render("#card-number-field-container");

                        const cvvField = cardField.CVVField();
                        cvvField.render("#card-cvv-field-container");

                        const expiryField = cardField.ExpiryField({
                            placeholder: "Data di scadenza (MM/AA)"
                        });
                        expiryField.render("#card-expiry-field-container");

                        // Add click listener to submit button and call the submit function on the CardField component
                        const paypalContainer = document.getElementById('paypal-direct-container')
                        const cardFieldButton = document.getElementById("card-field-submit-button")
                        if (cardFieldButton){
                            cardFieldButton.addEventListener("click", () => {
                                cardField.getState().then((data: any) => {
                                    // Submit only if the current state of the form is valid
                                    if (data.isFormValid) {
                                        setPaypalError("")
                                        setClickedPayWithPaypal(true)
                                        cardField.submit().then(() => {
                                            setClickedPayWithPaypal(false)
                                        });
                                    } else {
                                        setPaypalError("Ricontrolla i dati inseriti")
                                    }
                                });
                            });
                        }
                        if (paypalContainer) {
                            setTimeout(() => {
                                setLoadingPaypal(false);
                                paypalContainer.style.display = 'block';
                            }, 3000);
                        }
                    }
                    const younitedComponent = document.getElementById("younited-close");
                    if (younitedComponent) {
                        younitedComponent.click();
                    }
                    setApplepayDisabled(false)
                } catch (error) {
                    console.error("failed to render the PayPal Buttons", error);
                }
            })
        }
    }

    const loadKlarnaForm = () => {
        setClickedPay(true)
        ajax$(calls.Project.getActiveCart()).subscribe(async (active_cart) => {
            AWSlogger({
                type: "log",
                action: "clickPaymentButton",
                cartId: active_cart.id,
                payment: "klarna",
                browserInfo: getBrowserInfo(),
                cart: getCartInfo(active_cart)
            })

            const adyen_configuration = {
                locale: ADYEN_LOCALE,
                environment: ADYEN_ENVIRONMENT,
                clientKey: ADYEN_CLIENT_KEY,
                paymentMethodsResponse: { paymentMethods: ADYEN_PAYMENTMETHODS },
                onAdditionalDetails: onAdditionalDetails,
                onSubmit: (state: any, component: any) => {
                    createPayment(state, component, checkout, false, "klarna")
                },
                showPayButton: true,
                setStatusAutomatically: true,
                onChange: handleOnChange,
                amount: { value: active_cart.totalPrice.centAmount, currency: PROJECT_CURRENCY },
            }
            const checkout = await AdyenCheckout(adyen_configuration)
            unmountAdyenComponents()
            if (PROJECT_COUNTRY === "DE") {
                setKlarnaComponent(checkout.create("klarna").mount("#klarna-container"))
            } else {
                setKlarnaComponent(checkout.create("klarna_account").mount("#klarna-container"))
            }
            const younitedComponent = document.getElementById("younited-close");
            if (younitedComponent) {
                younitedComponent.click();
            }
            setApplepayDisabled(false)
        })
    }

    return (
        <CheckoutContext.Provider value={{
            shippingType,
            setShippingType,
            tempAddresses,
            setTempAddresses,
            privacy,
            setPrivacy,
            consent,
            setConsent,
            externalWarrantyCheck,
            setEternalWarrantyCheck,
            nlConsentValue,
            setNlConsentValue,
            commercial,
            setCommercial,
            activeStep,
            setActiveStep,
            focusPrivacy,
            setFocusPrivacy,
            consent3Party,
            setConsent3Party,
            clickedPay,
            setClickedPay,
            clickedPayWithPaypal,
            setClickedPayWithPaypal,
            paypalError,
            setPaypalError,
            applepayEnabled,
            setApplepayDisabled,
            lineItemWithNoAvailability,
            setLineItemWithNoAvailability,
            sepaComponent,
            setSepaComponent,
            adyenComponent,
            setAdyenComponent,
            paypalComponent,
            setPaypalComponent,
            klarnaComponent,
            setKlarnaComponent,
            bancontactComponent,
            setBancontactComponent,
            applepayComponent,
            setApplepayComponent,
            idealComponent,
            setIdealComponent,
            showPaypal,
            setShowPaypal,
            loadingPaypal,
            setLoadingPaypal,
            enabledGiftCardEditButton,
            setEnabledGiftCardEditButton,
            giftcardInsideCard,
            setGiftcardInsideCart,
            hasGiftCards,
            setHasGiftCards,
            showGiftCardSelect,
            setShowGiftCardSelect,
            unmountAdyenComponents,
            onAdditionalDetails,
            handleOnChange,
            hasExternalWarrantyService,
            loadIdealForm,
            loadBancontactForm,
            loadApplePayForm,
            loadAdyenForm,
            loadSepaForm,
            loadPaypalForm,
            loadPaypalDirectForm,
            loadKlarnaForm,
            payWithBankTransfer,
            closeOrderWithGiftCard,
            payLaterWithPayByLink,
            redirectToPayU,
            redirectToYounitedPay,
            redirectToBuyBox,
            redirectToPayFast,
            checkoutStep,
            setCheckoutStep,
            isMandatoryFieldsFlagged,
            useShippingAddressAsBilling,
            setUseShippingAddressAsBilling,
            makeSignUp,
            setMakeSignUp,
            addAddressFormVisibility,
            setAddAddressFormVisibility: setAddAddressFormVisibilityHandler,
            isAddressEdit,
            setIsAddressEdit: setIsAddressEditHandler,
            numberAddressAvailable,
            setNumberAddressAvailable: setNumberAddressAvailableHandler,
            selectedAddress,
            setSelectedAddress: setSelectedAddressByTypeHandler,
            showListAddress,
            setShowListAddress: setShowListAddressHandler,
        }}>
            {props.children}
        </CheckoutContext.Provider>
    );
};
